import {inject, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterModule, Routes} from '@angular/router';
import {ErrorPageComponent} from './components/pages/error-page/error-page.component';
import {HomepageTwoComponent} from './components/pages/homepage-two/homepage-two.component';
import {AboutPageComponent} from './components/pages/about-page/about-page.component';
import {TeamPageComponent} from './components/pages/team-page/team-page.component';
import {ContactPageComponent} from './components/pages/contact-page/contact-page.component';
import {BlogPageComponent} from './components/pages/blog-page/blog-page.component';
import {BlogDetailsPageComponent} from './components/pages/blog-details-page/blog-details-page.component';
import {FaqPageComponent} from './components/pages/faq-page/faq-page.component';
import {CoursesPageComponent} from './components/pages/courses-page/courses-page.component';
import {SimplePageComponent} from './components/pages/simple-page/simple-page.component';
import {BlogService} from "./service/blog.service";
import {privacyEnCookiebeleid} from "./data/pages/privacy-en-cookiebeleid";
import {algemenevoorwaarden} from "./data/pages/algemenevoorwaarden";
import {clubFox} from "./data/pages/clubfox";
import {geoDesk} from "./data/pages/geodesk";
import {
    ConsultancyServicesPageComponent
} from "./components/pages/consultancy-services-page/consultancy-services-page.component";
import {CustomSoftwarePageComponent} from "./components/pages/custom-software-page/custom-software-page.component";
import {
    TestimonialsComponent
} from "./components/pages/homepage-two/hometwo-testimonials/testimonials.component";

const routes: Routes = [
    {path: '', component: HomepageTwoComponent},
    {path: 'about-us', component: AboutPageComponent},
    {path: 'team', component: TeamPageComponent},
    // {path: 'services', component: ServicesPageComponent},
    // {path: 'services-details', component: ServicesDetailsPageComponent},
    // {path: 'gallery', component: GalleryPageComponent},
    {
        path: 'product',
        children: [
            {
                path: 'clubfox',
                component: SimplePageComponent,
                data: {
                    page: clubFox
                }
            },
            {
                path: 'geo-desk',
                component: SimplePageComponent,
                data: {
                    page: geoDesk,

                    addons: [TestimonialsComponent]
                }
            }
        ]
    },

    {
        path: 'services/consultancy',
        component: ConsultancyServicesPageComponent
    },
    {
        path: 'services/software-op-maat',
        component: CustomSoftwarePageComponent
    },
    {path: 'courses', component: CoursesPageComponent},
    {
        path: 'privacy', component: SimplePageComponent, data: {
            page: privacyEnCookiebeleid
        }
    },
    {
        path: 'algemenevoorwaarden', component: SimplePageComponent, data: {
            page: algemenevoorwaarden
        }
    },
    // {path: 'courses-details', component: CoursesDetailsPageComponent},
    // {path: 'events', component: EventsPageComponent},
    // {path: 'events-details', component: EventsDetailsPageComponent},
    // {path: 'case-studies-3-columns', component: CaseStudiesThreeColumnsPageComponent},
    // {path: 'case-studies-details', component: CaseStudiesDetailsPageComponent},
    {path: 'error', component: ErrorPageComponent},
    {path: 'faq', component: FaqPageComponent},
    {path: 'blog', component: BlogPageComponent},
    {
        path: 'blog/:id',
        component: BlogDetailsPageComponent,
        canActivate: [
            (next: ActivatedRouteSnapshot) => {
                let idAsParam = next.params['id'];
                console.info("ID: idAsParam")
                let blog = inject(BlogService).getBlog(idAsParam);
                if (blog == null) return inject(Router).createUrlTree(['not-found'])
                return blog != null
            }
        ],
        resolve: {
            blog: (snapshot: ActivatedRouteSnapshot) => {
                let idAsParam = snapshot.params['id'];
                let blog = inject(BlogService).getBlog(idAsParam);
                return blog
            }
        }
    },
    {path: 'contact', component: ContactPageComponent},

    // Here add new component

    {path: '**', component: ErrorPageComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
