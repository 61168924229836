<section class="about-area pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12" *ngFor="let Image of aboutImg;">
                <div class="about-img">
                    <img [src]="Image.img" alt="image">
                    <div class="shape"><img src="assets/img/about/shape1.png"></div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="content" *ngFor="let Content of aboutContent;">
                        <span class="sub-title"><img src="/assets/img/icon/Blaadje_klein.png" alt="image"> {{Content.subTitle}}</span>
                        <h2>{{Content.title}}</h2>
                        <p>{{Content.paragraphText1}}</p>
                        <ul class="about-list">
                            <li *ngFor="let List of aboutList;">
                                <span>
                                    <div class="icon">
                                        <i class="{{List.icon}}"></i>
                                    </div>
                                    {{List.title}}
                                    <img src="assets/img/our-mission/shape2.png" alt="image">
                                </span>
                            </li>
                        </ul>
                        <p>{{Content.paragraphText2}}</p>
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
