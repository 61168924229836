export const PIETER = {
    name: 'Pieter Degraeuwe',
    avatar: '/assets/img/user1.jpg'

} as Author

export const KATRIEN = {
    name: 'Katrien D\'Hooghe',
    avatar: '/assets/img/user1.jpg'

} as Author



export interface Author {
    name : string
    avatar : string
}

export interface Blog {
    id : string,
    title : string,
    subTitle : string,
    image : string,
    content: string,
    date : string
    author : Author
}


// postImg: 'assets/img/blog/img1.jpg',
//     postTitle: 'Six Ways to Make Smarter Decisions',
//     postLink: 'blog-details',
//     postDate: 'April 30, 2024',
//     postAuthorImage: 'assets/img/user1.jpg',
//     postAuthorName: 'Alex Morgan'
