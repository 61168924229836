export const menu = [
    { name : 'Home', url: '/'},
    { name : 'Wie zijn we', url: '/about-us'},
    {
        name : 'Diensten',
        children: [
            {name : 'Consultancy', url: '/services/consultancy' },
            {name : 'Software op maat', url: '/services/software-op-maat' }
        ]
    },
    {
        name : 'Producten',
        children: [
            {name : 'GEO-Desk', url: '/product/geo-desk' },
            {name : 'ClubFox', url: '/product/clubfox' }
        ]
    },
    { name : 'Blog', url: '/blog'}


]
