import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-related-courses',
    templateUrl: './related-courses.component.html',
    styleUrls: ['./related-courses.component.scss']
})
export class RelatedCoursesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'De voordelen op een rijtje'
        }
    ]
    singleCoursesBox: coursesContent[] = [
        {
            courseImg: 'assets/img/courses/img1.jpg',
            coursePrice: '$49',
            coursePriceClass: 'paid',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Sarah Taylor',
            title: 'Introduction to Quantitative Methods',
            link: 'courses-details',
            paragraphText: '* Rechtstreekse integratie met VLM, SNapp en SMIL * Mogelijkheid om meer staalnames te verwerken * Drastische verlaging foutmarge staalnamegegevens * Betere statistische inzichten',
            courseDuration: '8 Weeks Long',
            studentApply: 'Available Now'
        },

        {
            courseImg: 'assets/img/courses/img2.jpg',
            coursePrice: '$49',
            coursePriceClass: 'paid',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Sarah Taylor',
            title: 'Introduction to Linear Models and Matrix Algebra',
            link: 'courses-details',
            paragraphText: '* Efficiëntere manier om staalnames te verwerken * Tijdswinst door het digitaal ontvangen van staalgegevens * Kaartmateriaal wordt automatisch aangemaakt * Sneller kunnen ingrijpen dankzij actueel overzicht',
            courseDuration: '7 Weeks Long',
            studentApply: 'Available Now'
        },
        {
            courseImg: 'assets/img/courses/img3.jpg',
            coursePrice: '$69',
            coursePriceClass: 'paid',
            authorImg: 'assets/img/user3.jpg',
            authorName: 'David Warner',
            title: 'Data Science: Inference and Modeling',
            link: 'courses-details',
            paragraphText: '* Sneller toegang tot de informatie van de staalnames * Mogelijheid om vooraf eigen planning te maken * Eenvoudige manier om staalgegevens te registreren * Conform vereisten controlerende instanties',
            courseDuration: '2 Weeks Long',
            studentApply: 'Not Available'
        }
    ]

}
class coursesContent {
    courseImg : string;
    coursePrice : string;
    coursePriceClass : string;
    authorImg : string;
    authorName : string;
    title : string;
    link : string;
    paragraphText : string;
    courseDuration : string;
    studentApply : string;
}
class sectionTitleContent {
    title : string;
}
