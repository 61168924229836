import {Blog, KATRIEN, PIETER} from "./blog";

// postImg: 'assets/img/blog/img1.jpg',
//     postTitle: 'Six Ways to Make Smarter Decisions',
//     postLink: 'blog-details',
//     postDate: 'April 30, 2024',
//     postAuthorImage: 'assets/img/user1.jpg',
//     postAuthorName: 'Alex Morgan'


export const testBlog: Blog = {
    id: "1",
    title: 'Groene stroom',
    subTitle: 'Al onze servers draaien op groene stroom',
    author: PIETER,
    date: "7 maart 2012",
    image: 'assets/img/blog/Blog_maart2012_1_700x600.jpg',
    content: `

                        <p>We zijn er (eindelijk) in geslaagd om onze productieservers te verplaatsen naar een datacenter die wordt aangedreven met 100% groene energie (0% CO2 uitstoot). </p>

                        <p>Onze partner, Hetzner, werd in 2011 beloond voor zijn innovatieve en efficiënte datacenter.</p>



                        <blockquote class="wp-block-quote">
                            <p>Be part of the solution, not part of the pollution</p>


                        </blockquote>

                        <!--
                        <p>We blijven ernaar streven om monsternames van planning tot en met verwerking te vereenvoudigen en te optimaliseren.</p>
                        -->

                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog1_maart 2012/Blog_Groene stroom1_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog1_maart 2012/Blog_Groene stroom2_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog1_maart 2012/Blog_Groene stroom3_750x650.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>

                        <!--
                        <h3>Four major elements that we offer:</h3>

                        <ul class="features-list">
                            <li><i class='bx bx-badge-check'></i> Scientific skills for getting a better result</li>
                            <li><i class='bx bx-badge-check'></i> Communication skills to getting in touch</li>
                            <li><i class='bx bx-badge-check'></i> A career overview opportunity available</li>
                            <li><i class='bx bx-badge-check'></i> A good work environment for work</li>
                        </ul>


                        <h3>Beveiliging</h3>
                        <p>Beveiliging, vertrouwelijkheid, back-ups, bevoegdheden staalnemer en labomedewerkers, ... zijn ontzettend belangrijk. Deze luiken werden grondig herbekeken, vereenvoudigd en vastgelegd in procedures. Volledige transparant naar de klant toe.</p>


                        <h3>The rise of marketing and why you need it</h3>


                        <p>Heb je nog vragen of nood aan persoonlijk advies? Contacteer ons.</p>
                        -->


    `

}
