<div class="partner-area pt-100 pb-70 bg-f1f8fb">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-4 col-md-4" *ngFor="let Item of partnerItems;">
                <div class="single-partner-item">
                    <img [src]="Item.img" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="divider white-color"></div>
</div>
