import {Blog, KATRIEN, PIETER} from "./blog";

// postImg: 'assets/img/blog/img1.jpg',
//     postTitle: 'Six Ways to Make Smarter Decisions',
//     postLink: 'blog-details',
//     postDate: 'April 30, 2024',
//     postAuthorImage: 'assets/img/user1.jpg',
//     postAuthorName: 'Alex Morgan'


export const testBlog4: Blog = {
    id: "4",
    title: 'Succesvolle nitraatcampagne 2022',
    subTitle: 'Ook in 2022 een succesvolle nitraatcampagne',
    author: PIETER,
    date: "20 januari 2023",
    image: 'assets/img/blog/Blog4_jan 2023/Blog_jan2023_2_700x600.jpg',
    content: `

                        <p>Ook in 2022 hebben onze klanten zeer veel meerwaarde ervaren aan GEO-Desk. Het opvolgen van de staalnames, controleren looppatronen etc. verloopt zoveel vlotter wanneer je software hebt die daar specifiek op maat voor is geschreven!</p>

                        <p>Door middel van integratie met VLM wordt ook het voormelden een stuk makkelijker.</p>

                        <p>Niet alleen de labo's zelf maar ook de staalnemers zijn enthousiast. Duidelijk kaartmateriaal, inplannen, opladen GPS logs, etc.</p>


                        <blockquote class="wp-block-quote">
                            <p>Think of yourself as a resource to your clients; an adviser, counselor, mentor and friend.</p>

                            <cite>Brian Tracy</cite>


                        </blockquote>

                        <p>Tevreden klanten. Daar doen we het voor!</p>


                        <ul class="wp-block-gallery columns-1">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog4_jan 2023/Blog_jan2023_2.jpg" alt="image">
                                </figure>
                            </li>

                        </ul>

                        <!--
                        <h3>Four major elements that we offer:</h3>

                        <ul class="features-list">
                            <li><i class='bx bx-badge-check'></i> Scientific skills for getting a better result</li>
                            <li><i class='bx bx-badge-check'></i> Communication skills to getting in touch</li>
                            <li><i class='bx bx-badge-check'></i> A career overview opportunity available</li>
                            <li><i class='bx bx-badge-check'></i> A good work environment for work</li>
                        </ul>


                        <h3>Beveiliging</h3>
                        <p>Beveiliging, vertrouwelijkheid, back-ups, bevoegdheden staalnemer en labomedewerkers, ... zijn ontzettend belangrijk. Deze luiken werden grondig herbekeken, vereenvoudigd en vastgelegd in procedures. Volledige transparant naar de klant toe.</p>


                        <h3>The rise of marketing and why you need it</h3>
                        -->

                        <p>Heb je nog vragen of nood aan persoonlijk advies? Contacteer ons.</p>

                        <div class="option-item">
                           <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Contacteer ons<span></span></a>
                        </div>

    `

}
