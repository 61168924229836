export const privacyEnCookiebeleid = {
    title: 'Privacy en Cookiebeleid',
    content : `

<p>
    Privacybeleid
    <br/>
    <br/>
    Privacybeleid en gebruiksvoorwaarden omtrent persoonsgegevens van Systemworks bv
    <br/>
    <br/>
    Laatst bijgewerkt: januari 2024
    <br/>
    <br/>
    ALGEMEEN
    <br/>
    <br/>
    Dit privacybeleid is van toepassing vanaf 1 januari 2024 en gaat uit van Systemworks bv, met zetel te 9070 Destelbergen, Belgiëlaan 61 en met ondernemingsnummer 0871.705.049 (Hierna Systemworks bv)
    <br/>
    <br/>
    Systemworks bv levert software op maat en verzorgt uw IT-diensten.
    <br/>
    <br/>
    We hechten groot belang aan uw privacy en dragen er zorg voor dat de persoonlijke informatie die u ons verschaft vertrouwelijk wordt behandeld. Tijdens uw contact met Systemworks bv kan het voorkomen dat u persoonlijke informatie met ons deelt, zodat wij u kunnen indentificeren als individu, medewerker van een bedrijf, ...
    <br/>
    Zoals uw volledige naam, adres en telefoonnummer, ... Dit zijn uw "persoonsgegevens".
    <br/>
    <br/>
    Deze privacyverklaring is van toepassing op de persoonsgegevens die we over u verzamelen met als doel u onze producten en diensten aan te bieden. Door gebruik te maken van de site of door ons uw persoonsgegevens mee te delen bij onze diensten, aanvaardt u de praktijken die in deze Privacyverklaring worden beschreven.
    <br/>
    <br/>
    <br/>
    DE VERWERKING VAN PERSOONSGEGEVENS
    <br/>
    <br/>
    Systemworks bv als Verweringsverantwoordelijke
    <br/>
    <br/>
    Systemworks bv verzamelt, registreert en verwerkt persoonsgegevens van klanten en gebruikers van onze diensten. Gegevens worden bijvoorbeeld meegedeeld door personen zelf bij een contactopname, bij registratie op ons platform of wanneer door Systemworks bv Softwarediensten worden verleend.Dit zijn onder andere de gegevens van uw onderneming, naam, adres, contactgegevens, ...
    <br/>
    <br/>
    De persoonsgegevens worden steeds verwerkt met inachtneming van de principes van de privacywetgeving en de Algemene Verordening Gegevensbescherming, zijnde de Europese Verordering 2016/679 van 27 april 2016 betreffende de bescherming van persoonsgegevens (hierna 'GDPR').
    <br/>
    De persoonsgegevens worden verwerkt op een rechtmatige, behoorlijke en transparante manier en dit voor een welbepaald doel. De gegevensverwerking wordt beperkt tot zolang als nodig met betrekking tot het realiseren van de doeleinden van de verwerking.
    <br/>
    De persoonsgegevens worden op een passende manier beveiligd middels passende technische en organisatoriscche maatregelen.
    <br/>
    Persoonsgegevens worden niet buiten de EEG gebracht behalve met de nodige waarborgen en garanties conform de GDPR.
    <br/>
    <br/>
    Systemworks bv verwerkt de persoonsgegevens, onder andere voor de volgende doelen:
    <br/>
    * Klantenbeheer
    <br/>
    * U informatie te bezorgen over onze professionele en sociale activiteiten, of andere onderwerpen die u kan aanbelangen
    <br/>
    * U te kunnen contacteren indien dit nodig is om onze dienstverlening uit te kunnen voeren
    <br/>
    * Voor de verdere uitvoering van overeenkommsten
    <br/>
    * U te informeren over wijzigingen van onze diensten en producten
    <br/>
    * Om goederen en diensten bij u af te leveren
    <br/>
    * Het afhandelen van uw betaling
    <br/>
    * Om wettelijke verplichtingen na te komen
    <br/>
    * Wanneer het legitiem belang van de onderneming dit vereist, maar dit steeds duidelijk is naar de betrokkene toe.
    <br/>
    <br/>
    Andere gegevens worden op geautomatiseerde wijze verzameld door 'cookies' en andere systemen/applicaties die informatie verzamelen.
    <br/>
    Een cookie is een klein tekstbestand dat bij het eerste bezoek aan de website wordt opgeslagen op uw computer, tablet of smartphone.De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak.
    <br/>
    Ze zorgen ervoor dat de website naar behoren werkt en onderhouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren.
    <br/>
    U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.
    <br/>
    <br/>
    Systemworks bv als Verwerker
    <br/>
    <br/>
    Systemworks bv levert bedrijfssoftware op maat van ondernemingen en zorgt voor IT-oplossingen.
    <br/>
    <br/>
    Met betrekking tot de verwerking van eventuele persoonsgegevens, die door de KLANT worden opgeslagen op onze servers of op datacenters onder ons beheer, zal de KLANT de rol van "Verwerkingsverantwoordelijke" en Systemworks bv de rol van "Verwerker" hebben,
    <br/>
    zoals beschreven in de EU Algemene Verordering Gegevensbescherming EU 2016/679). De instructies van de klant beperken zich tot het afleveren van een goed functionerend platform en het opslaan van deze gegevens.
    <br/>
    <br/>
    Systemworks bv heeft niet de verplichting om de gegevens die door de klant worden opgeslagen op zijn platform te controleren of er kennis van te hebben.
    <br/>
    Systemworks bv heeft evenwel het recht (maar niet de plicht) om die gegevens op elk moment en zonder voorafgaande kenninsgeving te verwijderen, als die gegevens naar het redelijk oordeel van Systemworks bv illegaal zijn, of de rechten van derden schenden (of dreigen te schenden).
    <br/>
    Systemworks bv heeft evenwel het recht om die gegevens op elk moment en zonder voorafgaande kennisgeving te verwijderen op verzoek van de bevoegde autoriteiten.
    <br/>
    <br/>
    Systemworks bv zal de persoonsgegevens verwerken volgens de instructies van de klant en/of in overeenstemming met de levering van de diensten uit de overeenkomst tussen Systemworks bv en de klant, of zoals wordt vereist door de wet, regelgevende instantie of bevoegde autoriteit.
    <br/>
    In dat laatste geval zal Systemworks bv de klant vooraf informeren voor zover als mogelijk, tenzij de relevante wet, regelgevende instantie of bevoegde autoriteit dat verbiedt.
    <br/>
    <br/>
    De klant aanvaardt dat hij de primaire verantwoordelijke is voor de verwerking van persoonsgegevens en volgt het design van Systemworks bv op behoorlijke en juist wijze op. Systemworks bv zal rekenig houdende met de aarde van de verwerking en de hem ter beschikking staande informatie, de klant in de mate van het mogelijke bijstand verlenen bij het doen nakomen van de verplichtingen uit hoofde van d artikelen 32 tot en met 36 GDPR.
    <br/>
    De klant zal al deze bijstandsprestaties vergoeden.
    <br/>
    <br/>
    * Bijstand inzake beveiliging van de verwerking (Artikel 32 GDPR).
    <br/>
    * Bijstand inzake datalek (Artikelen 33 en 34 GDPR) - Systemworks bv informeert de klant zonder onredelijke vertraging zodra hij kennis heeft genomen van een inbreuk in verband met persoonsgegevens.
    <br/>
    * Bijstand inzake een Privacy Impact Assessment (Artikelen 35 en 36 GDPR) - Wanneer een soort verwerking een hoog risico inhoudt voor de rechten en vrijheden van natuurlijke personen voert de klant voor de verwerking een beoordeling uit van het effect van de beoogde verwerkingsactiviteiten op de bescherming van persoonsgegevens conform artikelen 35 en 36 GDPR.
    <br/>
    <br/>
    Bij de beëindiging van deze overeenkomst zal Systemworks bv, indien de klant hierom verzoekt, de persoonsgegevens en/of bestaande kopieën onmiddellijk teruggegven of vernietigen, voor zover dat redelijkerwijs mogelijk is. Systemworks bv zal bevestigen dat het dit heeft gedaan (behalve indien een relevante wet of autoriteit Systemworks bv verplicht om een kopie van de persoonsgegevens te bewaren).
    <br/>
    Voor alle duidelijkheid, niets in dit punt verplicht Systemworks bv om kopieën van gegevens te vernietigen die het in eigen naam als Verwerkingsverantwoordelijke houdt.
    <br/>
    <br/>
    <br/>
    Partners en Verwerkers van Systemworks bv
    <br/>
    <br/>
    Systemworks bv opteert voor partners die eenzelfde niveau van beveiliging en vertrouwelijkheid van uw persoonsgegevens kunnen waarborgen.
    <br/>
    <br/>
    Systemworks bv zal de persoonsgegevens uitsluitend verstrekken aan verwerkers of gelieerde vennootschappen indien dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting of wanneer het legitiem belang van de onderneming dit vereist en dit verenigbaar is met het doel van de verwerking en dit op een proportionele wijze gebeurt.
    <br/>
    <br/>
    De klanten kunnen bezwaar indienen tegen Verwerkers, indien zij dit niet doen gaan zij impliciet akkoord.
    <br/>
    <br/>
    <br/>
    Rechten van de betrokkene
    <br/>
    <br/>
    Wil u uw gegevens raadplegen, overdragen, wijzigen, corrigeren, beperken of verwijderen dan kan u hiervoor kosteloos een mail versturen, mits bewijs door kopie van uw identiteitskaart (zonder foto), naar het mailadres infoDsystemworks.be. Via deze weg en op dezelfde wijze kan u ook bezwaar indienen tegen de verwerking van persoonsgegevens of wanneer u de communicatie wenst te beëindigen.
    <br/>
    De gegevens worden aangepast of verwijderd in de mate van het mogelijke en naargelang de technische mogelijkheden het toelaten. Uw aanvraag zal zo snel mogelijk worden behandeld. Er zal zoveel als mogelijk aan uw vraag worden voldaan, behalve als dit door het belang van de onderneming of een wettelijke verplichting niet mogelijk blijkt te zijn.
    <br/>
    Wanneer er sprake is van een datalek waardoor uw rechten en de bescherming van uw persoonsgegevens dreigen te worden geschonden zal de toezichthoudende autoriteit hiervan op de hoogte worden gesteld en u, bij een hoog risico voor uw rechten en vrijheden van natuurlijke personen, als betrokkene worden geïnformeerd.
    <br/>
    <br/>
    <br/>
    Veiligheid en vertrouwelijkheid
    <br/>
    <br/>
    Systemworks bv heeft veiligheidsmaatregelen ontwikkeld, die aangepast zijn op technisch en organisatorisch vlak, om de vernietiging, het verlies, de vervalsing, de wijziging, de niet-toegestane toegang of de kennisgeving per vergissing aan derden van persoonsgegevens verzameld op de website, te vermijden alsook elke andere niet toegestane verwerking van deze gegevens. In geen geval kan Systemworks bv aansprakelijk worden geacht voor enige directe of indirecte schade die voortvloeit uit een foutief of onrechtmatig gebruik door een derde van de persoonsgegevens.
    <br/>
    <br/>
    Alle gegevens worden steeds vertrouwelijk behandeld.
    <br/>
    <br/>
    Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op via infoDsystemworks.be
    <br/>
    <br/>
    <br/>
    Juistheid van uw gegevens
    <br/>
    <br/>
    U garandeert dat de gegevens die u meedeelt juist en volledig zijn. Het meedelen van onjuiste gegevens of gegevens die toebehoren aan derden kan ertoe leiden dat de gebruiker, tijdelijk of definitief, iedere toegang, geheel of gedeeltelijk, wordt ontzegd tot de producten en diensten van Systemworks bv.
    <br/>
    <br/>
    <br/>
    Aangaande minderjarigen
    <br/>
    <br/>
    Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar tenzij ze toestemming hebben van ouders of voogd. Wij raden ouders dan ook aan betrokken te zijn bij de onlineactiviteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming.
    <br/>
    Als je ervan overtuigd bent dat wij zonder toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via infoDsystemworks.be, dan zullen wij deze informatie verwijderen.
    <br/>
    <br/>
    <br/>
    Intellectuele eigendomsrechten
    <br/>
    <br/>
    De inhoud van deze site, met inbegrip van de merken, logo's, tekeningen, data, product- of bedrijfsnamen, teksten, beelden e.d. zijn beschermd door intellectuele rechten en behoren toe aan Systemworks bv.
    <br/>
    <br/>
    <br/>
    Informatie op de website
    <br/>
    <br/>
    Door gebruik te maken van deze website verbindt u zich ertoe volgende gebruiksvoorwaarden na te leven en te aanvaarden. Systemworks bv bezit het exclusieve copyright en de intellectuele rechten van deze website, zijn design en de volledige inhoud ervan. Gebruik van deze website, of delen ervan, in welke vorm dan ook, is verboden zonder de voorafgaande schriftelijke toestemming van Systemworks bv.
    <br/>
    <br/>
    Het is verboden zonder voorafgaand schriftelijk akkoord de op en via deze website aangeboden informatie op te slaan (anders dan noodzakelijk om de website te kunnen bekijken), te reproduceren, te wijzigen, openbaar te maken, te distribueren of te verzenden, te verkopen of anderszins over te dragen of enige rechten hierop te verlenen aan derden.
    <br/>
    <br/>
    De informatie op de website is van algemene aard. De informatie is niet aangepast aan persoonlijke of specifieke omstandigheden, en kan dus niet als een persoonlijk, professioneel of juridisch advies aan de gebruiker worden beschouwd.
    <br/>
    <br/>
    <br/>
    De website kan hyperlinks bevatten naar websites of pagina's van derden, of daar onrechtstreeks naar verwijzen. Het plaatsen van links naar deze websites of pagina's impliceert op geen enkele wijze een impliciete goedkeuring van de inhoud ervan. Zo kan ons platform geconnecteerd worden met andere systemen, maar verwerkt Systemworks bv deze gegevens niet. Systemworks bv verklaart uitdrukkelijk dat zij geen zeggenschap heeft over de inhoud of over andere kenmerken van deze websites en kan in geen geval aansprakelijk gehouden worden voor de inhoud of de kenmerken ervan of voor enige andere vorm van schade door het gebruik ervan.
    <br/>
    <br/>
    <br/>
    Aansprakelijkheid
    <br/>
    <br/>
    Aansprakelijkheid algemeen
    <br/>
    <br/>
    Systemworks bv kan evenwel niet aansprakelijk worden gesteld voor rechtstreekse of onrechtstreekse schade die ontstaat uit het gebruik van de informatie op deze site.
    <br/>
    <br/>
    Indien u onjuistheden zou vaststellen in de informatie die via de site ter beschikking wordt gesteld, kan u de beheerder van de site contacteren.
    <br/>
    <br/>
    De inhoud van de site (links inbegrepen) kan ten allen tijde zonder aankondiging of kennisgeving aangepast, gewijzigd of aangevuld worden. Systemworks bv geeft geen garanties voor de goede werking van de website en kan op geen enkele wijze aansprakelijk gehouden worden voor een slechte werking of tijdelijke (on)beschikbaarheid van de website of voor enige vorm van schade, rechtstreekse of onrechtstreekse, die zou voortvloeien uit de toegang tot of het gebruik van de website.
    <br/>
    <br/>
    Systemworks bv kan in geen geval tegenover wie dan ook, op direct of indirecte, bijzondere of andere wijze aansprakelijk worden gesteld voor schade te wijten aan het gebruik van deze site of van een andere, inzonderheid als gevolg van links of hyperlinks, met inbegrip, zonder beperking, van alle verliezen, werkonderbrekingen, beschadiging van programma's of andere gegevens op het computersysteem, van apparatuur, programmatuur of andere van de gebruiker.
    <br/>
    <br/>
    De totale aansprakelijkheid van Systemworks bv zal in ieder geval niet groter kunnen zijn dan het totale bedrag aan kosten dat door de klant betaald werd zoals bepaald in huidige overeenkomst met een maximum van 2500 Euro.
    <br/>
    <br/>
    <br/>
    Aansprakelijkheid als Verwerker
    <br/>
    <br/>
    De klant vrijwaart Systemworks bv voor alle kosten, uitgaven (ook juridische), schadeloosstellingen, verliezen (met inbegrip van zakelijk verlies of verlies van baten), schulden, eisen, vorderingen, procedures of rechtzaken, ... waarvan Systemworks bv het voorwerp zou kunnen worden als gevolg van:
    <br/>
    - De uitvoering door Systemworks bv van instructies die de klant geeft in verband met de verwerking van persoonsgegevens (met inbegrip van instructies in verband met verzoeken van personen die hun rechten volgens de Wetgeving Gegevensbescherming uitoefenen, en instructies om persoonsgegevens te bewaren, te onthullen, aan te passen of op andere manieren te verwerken) of
    <br/>
    - elke inbreuk door de klant op dit artikel
    <br/>
    <br/>
    De aansprakelijkheid van Systemworks bv is steeds beperkt tot deze gevallen specifiek voorzien in de GDPR. Systemworks bv is slechts aansprakelijk voor de schade die door verwerking is veroorzaakt wanneer bij de verwerking niet is voldaan aan de specifiek tot rechtmatige instructies van de verwerkingsverantwoordelijke is gehandeld. De aansprakeijkheid van Systemworks bv is in alle gevallen beperkt tot de directe schade.
    <br/>
    <br/>
    De klant vrijwaart Systemworks bv te allen tijde voor alle aanspraken van derden. De totale aansprakelijkheid van Systemworks bv zal in ieder geval niet groter kunnen zijn dan het totale bedrag aan kosten datt door de klant betaald werd zoals bepaald in huidige overeenkomst met een maximum van 2500 Euro.
    <br/>
    <br/>
    Indien aangaande de verwerking van persoonsgegevens één van de voorafgaande bepalingen niet geldig zou zijn, dan wordt deze vervangen door een bepaling die het onderliggend doel van de vooropgestelde bepaling zoveel als mogelijk benaderen.
    <br/>
    <br/>
    <br/>
    Het privacybeleid bij Systemworks bv is onderworpen aan het Belgisch recht.
    <br/>
    <br/>
    De Belgische autoriteiten (bv. de gegevensbeschermingsautoriteit) en de Belgische rechtbanken, meer specifiek diegene van het arrondissement Gent zijn, bij uitsluiting van alle andere, bevoegd om kennis te nemen van geschillen en klachten die zouden rijzen met betrekking tot deze website of het gebruik ervan of aangaande de verwerking van persoonsgegevens.
    <br/>
    <br/>
    <br/>
    <br/>
    Cookiebeleid
    <br/>
    <br/>
    Systemworks bv
    <br/>
    <br/>
    <br/>
</p>






    `
}
