import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BehaviorSubject, map} from "rxjs";

interface Mount {
    id: number
    createdAt: Date
    updatedAt: Date
    publishedAt: Date
    url: string
    pageId : number
}

@Injectable({
    providedIn: 'root'
})
export class MountServiceService {

    public mounts : BehaviorSubject<Array<Mount>> = new BehaviorSubject([])

    constructor(/*private _http: HttpClient*/) {

/*
        _http.get(`${environment.strapiHost}/api/sw-mounts?populate[page][fields][0]=id`).pipe(
            map((r: any) => r.data as Array<any>),
            map(r => r.map(m => {
                console.info(m)
                let mount = {} as Mount;
                mount.id = m.id
                mount.createdAt = m.attributes.createdAt
                mount.updatedAt = m.attributes.updatedAt
                mount.publishedAt = m.attributes.publishedAt
                mount.url = m.attributes.url
                mount.pageId = m.attributes.page.data.id
                return mount as Mount
            }))

        ).subscribe((r) => {
            this.mounts.next(r)
            console.info("MONUNTS: ", r)
        })
*/

    }
}
