import {Component, OnInit} from '@angular/core';
import {menu} from "../../../data/menu";


export interface MenuItem {
    name : string
    url ?: string
    children ?: Array<MenuItem>
}


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    public menu : Array<MenuItem>

    constructor() {

        this.menu = menu



    }

    ngOnInit(): void {
    }

}
