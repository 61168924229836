<footer class="{{footerClass}}">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo sw_blauw_versie 1.png" alt="logo"></a>
                    <p>Complete verantwoordelijkheid en zorg voor de ontwikkeling van toepassingen en de configuratie, het onderhoud, de monitoring en tuning van het platform.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Snelle links</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="">Homepage</a></li>
                        <li><a routerLink="/about-us">Wie zijn we</a></li>
<!--                        <li><a routerLink="/case-studies-3-columns">Case Studies</a></li>-->
                        <li><a routerLink="/blog">Blog</a></li>
                        <li><a routerLink="/contact">Contacteer ons</a></li>
                    </ul>
                </div>
            </div>

            <!--<div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/team">Our Scientists</a></li>
                        <li><a routerLink="/services">Diensten</a></li>
                        <li><a routerLink="/">Testimonials</a></li>
                        <li><a routerLink="/services-details">SaaS Solutions</a></li>
                        <li><a routerLink="/services-details">eCommerce</a></li>
                    </ul>
                </div>
            </div>-->

            <div class="col-lg-5 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Locatie</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Belgiëlaan 61 ° 9070 Destelbergen</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+32485686085">+32 485 68 60 85</a></li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+32473585326">+32 473 58 53 26</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info&#64;systemworks.be">info&#64;systemworks.be</a></li>
                        <li><i class='bx bxs-bank'></i>BE 871.705.049</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© Systemworks bv</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy">Privacybeleid</a></li>
                        <li><a routerLink="/algemenevoorwaarden">Algemene voorwaarden</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

<!--    <div class="footer-map"><img src="assets/img/footer-map.png" alt="image"></div>-->
</footer>

<div class="go-top"><i class="flaticon-up"></i></div>
