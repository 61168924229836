import { Component, OnInit } from '@angular/core';
import {homePage} from "../../../data/pages/homepage";

@Component({
  selector: 'app-homepage-two',
  templateUrl: './homepage-two.component.html',
  styleUrls: ['./homepage-two.component.scss']
})
export class HomepageTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

    protected readonly homePage = homePage;
}
