import {Blog, KATRIEN, PIETER} from "./blog";

// postImg: 'assets/img/blog/img1.jpg',
//     postTitle: 'Six Ways to Make Smarter Decisions',
//     postLink: 'blog-details',
//     postDate: 'April 30, 2024',
//     postAuthorImage: 'assets/img/user1.jpg',
//     postAuthorName: 'Alex Morgan'


export const testBlog6: Blog = {
    id: "6",
    title: 'Nieuwe nitraatresiducampagne',
    subTitle: 'Nitraatcampagne najaar 2023',
    author: KATRIEN,
    date: "3 augustus 2023",
    image: 'assets/img/blog/Blog6_aug 2023/Blog_aug2023_1_700x600.jpg',
    content: `

                        <p>Dit najaar gaat er een nieuwe nitraatresiducampagne van start.</p>

                        <p>Voor de deelnemende laboratoria en de staalnemers zijn een duidelijke dagplanning, kaartmateriaal, voormelden/afmelden/uitstellen,... ontzettend belangrijk. Vaak is dit heel tijdrovend en het overzicht hiervan behouden is cruciaal voor een vlot verloop van de campagne.</p>

                        <p>GEO-Desk neemt een heel groot deel van deze taken over.</p>

                        <p>Alle nog te bemonsteren percelen zijn in één oogopslag zichtbaar op de kaart en kunnen door de toegewezen staalnemer ingepland worden op basis van geografische ligging. Op deze manier kan er een goede dagplanning komen wat resulteert in tijdswinst en een hogere capaciteit. Bijhorend kaartmateriaal en etiketten zijn ter beschikking per dag, op volgorde van het gepland tijdstip.</p>

                        <p>Door de rechtstreekse integratie met de VLM kunnen percelen met één knop voorgemeld, afgemeld of uitgesteld worden. Na bemonstering en opladen van gps-gegevens worden de gegevens gekoppeld aan het juiste perceel en automatisch doorgestuurd naar de server van de VLM.</p>

                        <p>De laboratoria krijgen een overzicht van het aantal genomen en nog te nemen percelen per staalnemer, het aantal ontbrekende gps-gegevens, het aantal afwijkende looppatronen, ...</p>

                        <p>Tevens zijn er duidelijke handleidingen beschikbaar voor zowel staalnemer als laboratorium.</p>
                        <p>Gedurende de volledige campagne zijn we op elk moment van de dag bereikbaar ter ondersteuning indien nodig. En dit 7 op 7 voor zowel staalnemer als laboratorium.</p>


                        <blockquote class="wp-block-quote">
                            <p>Tijd is wat we het meest willen maar wat we het slechtst gebruiken.</p>


                        </blockquote>

                        <p>We blijven ernaar streven om monsternames van planning tot en met verwerking te vereenvoudigen en te optimaliseren.</p>

                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog6_aug 2023/Blog_aug2023_2_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog6_aug 2023/Blog_aug2023_3_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog6_aug 2023/Blog6_GEODesk3_750x650.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>

                        <!--
                        <h3>Four major elements that we offer:</h3>

                        <ul class="features-list">
                            <li><i class='bx bx-badge-check'></i> Scientific skills for getting a better result</li>
                            <li><i class='bx bx-badge-check'></i> Communication skills to getting in touch</li>
                            <li><i class='bx bx-badge-check'></i> A career overview opportunity available</li>
                            <li><i class='bx bx-badge-check'></i> A good work environment for work</li>
                        </ul>


                        <h3>Beveiliging</h3>
                         -->
                        <p>Wij zijn ervan overtuigd dat GEO-Desk een grote meerwaarde kan bieden tijdens de nitraatcampagne. Met vooral een duidelijk actueel overzicht voor laboratorium en staalnemer. Hierbij is de planningsfase van groot belang om de meeste zaken te stroomlijnen. En de automatisering zal er ongetwijfeld voor zorgen dat de foutmarge drastisch wordt verlaagd.</p>

                        <!--
                        <h3>The rise of marketing and why you need it</h3>
                        -->

                        <p>We kijken er alvast naar uit om de vele mogelijkheden en voordelen van GEO-Desk aan u te demonstreren.</p>

                        <p>Neem gerust contact op om een vrijblijvende demo in te plannen.</p>


                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Contacteer ons<span></span></a>
                        </div>
    `

}
