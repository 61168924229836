<ng-template #menuItemTemplate let-menuItem="menuItem">
    <ng-container *ngIf="menuItem.url != null">
        <li class="nav-item">
            <a [routerLink]="menuItem.url" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                {{ menuItem.name }}
            </a>
        </li>
    </ng-container>
    <ng-container *ngIf="menuItem.url == null">
        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">
            {{menuItem.name}}
            <i class='bx bx-chevron-down'></i></a>
            <ul class="dropdown-menu">
                <ng-container *ngFor="let childItem of menuItem.children">
                    <ng-container *ngTemplateOutlet="menuItemTemplate; context: {menuItem : childItem}"></ng-container>
                </ng-container>
            </ul>
        </li>

    </ng-container>

</ng-template>

<div class="navbar-area">
    <div class="wilo-responsive-nav">
        <div class="container">
            <div class="wilo-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/Logo SW_enkel naam.png" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="wilo-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/Logo SW_enkel naam.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <ng-container *ngFor="let menuItem of menu">
                            <ng-container *ngTemplateOutlet="menuItemTemplate; context: {menuItem : menuItem}"></ng-container>
                        </ng-container>
<!--
                        <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>

                        <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wie zijn we</a></li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Diensten <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Consultancy</a></li>

                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Software op maat</a></li>
                            </ul>
                        </li>


                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Producten <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">

&lt;!&ndash;                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>&ndash;&gt;

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">GEO-Desk <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses</a></li>

                                        <li class="nav-item"><a routerLink="/courses-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Club fox <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>

                                        <li class="nav-item"><a routerLink="/events-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

&lt;!&ndash;                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>&ndash;&gt;

                                <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contacteer ons</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>
-->
                    </ul>

                    <div class="others-option d-flex align-items-center">
<!--                        <div class="option-item">-->
<!--                            <form class="search-box">-->
<!--                                <input type="text" class="input-search" placeholder="Search for anything">-->
<!--                                <button type="submit"><i class="flaticon-loupe"></i></button>-->
<!--                            </form>-->
<!--                        </div>-->
                        <div class="option-item">
                            <app-contact-button></app-contact-button>
<!--                            <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Contacteer ons<span></span></a>-->
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>


</div>
