<app-hometwo-banner></app-hometwo-banner>

<app-hometwo-boxes></app-hometwo-boxes>

<!--<app-hometwo-about></app-hometwo-about>-->

<!--<app-hometwo-mission></app-hometwo-mission>-->

<app-hometwo-funfacts></app-hometwo-funfacts>

<!--<app-hometwo-services></app-hometwo-services>-->

<!--<app-hometwo-process></app-hometwo-process>-->

<!--<app-hometwo-scientist></app-hometwo-scientist>-->



<!--<app-project-start></app-project-start>-->

<app-home-blog></app-home-blog>

<app-testimonials [titleSection]="homePage.testimonialSection"></app-testimonials>

<app-partners [partnerItems]="homePage.partners"></app-partners>
