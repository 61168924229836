import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    @Input()
    partnerItems: Item[] = []

}


class Item {
    img : string;
}
