import {Blog, KATRIEN, PIETER} from "./blog";

// postImg: 'assets/img/blog/img1.jpg',
//     postTitle: 'Six Ways to Make Smarter Decisions',
//     postLink: 'blog-details',
//     postDate: 'April 30, 2024',
//     postAuthorImage: 'assets/img/user1.jpg',
//     postAuthorName: 'Alex Morgan'


export const testBlog5: Blog = {
    id: "5",
    title: 'Uitbreidingen GEO-Desk 2023',
    subTitle: 'Staalname Water',
    author: KATRIEN,
    date: "5 februari 2023",
    image: 'assets/img/blog/Blog5_feb 2023/Blog_feb2023_1_700x600.jpg',
    content: `

                        <p>De voorbije maanden hebben we ons onder andere toegespitst op een verfijning van het gebruik van GEO-Desk voor waterstalen.</p>

                        <p>Voor een staalnemer is het cruciaal om duidelijke locaties en richtlijnen te hebben om op deze manier zo weinig mogelijk tijd te verliezen tijdens de monstername.</p>

                        <p>Indien de plaatsen van staalname vooraf gekend zijn, is het mogelijk om deze op een eenvoudige manier in bulk te importeren.</p>

                        <p>Meetresultaten kunnen ter plaatse ingegeven worden, al dan niet met meldingen indien er overschrijdingen worden vastgesteld. Het is ook mogelijk om deze meetresultaten te versturen naar een extern platform (bv. LIMS).</p>

                        <p>Daarnaast hebben we ook aandacht besteed aan de mogelijkheid om manueel zaken toe te voegen op foto's. Denk maar aan het omcirkelen van de exacte plaats van de monstername.</p>



                        <blockquote class="wp-block-quote">
                            <p>Nieuwe ideeën ontwikkelen is niet moeilijk. Ontsnappen aan de oude wel.</p>


                        </blockquote>

                        <p>We blijven ernaar streven om monsternames van planning tot en met verwerking te vereenvoudigen en te optimaliseren.</p>

                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog5_feb 2023/blog_labo2_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog5_feb 2023/blog_labo3_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog5_feb 2023/blog_labo4_750x650.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>

                        <!--
                        <h3>Four major elements that we offer:</h3>

                        <ul class="features-list">
                            <li><i class='bx bx-badge-check'></i> Scientific skills for getting a better result</li>
                            <li><i class='bx bx-badge-check'></i> Communication skills to getting in touch</li>
                            <li><i class='bx bx-badge-check'></i> A career overview opportunity available</li>
                            <li><i class='bx bx-badge-check'></i> A good work environment for work</li>
                        </ul>
                        -->

                        <h3>Beveiliging</h3>
                        <p>Beveiliging, vertrouwelijkheid, back-ups, bevoegdheden staalnemer en labomedewerkers, ... zijn ontzettend belangrijk. Deze luiken werden grondig herbekeken, vereenvoudigd en vastgelegd in procedures. Volledige transparant naar de klant toe.</p>

                        <!--
                        <h3>The rise of marketing and why you need it</h3>
                        -->

                        <p>Heb je nog vragen of nood aan persoonlijk advies? Contacteer ons.</p>

                        <div class="option-item">
                           <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Contacteer ons<span></span></a>
                        </div>


    `

}
