//Eerst in wordt geschreven, daarna copy paste in   https://wordtohtml.net/

export const geoDesk = {
    title: 'GEO-Desk',
    content : `
        <h2>De slimste oplossing om staalnames te verwerken</h2>
        <p>&nbsp;</p>
        <p>Ben jij nog uren bezig met het overtypen van staalgegevens? Of kruipt er steeds veel tijd in de voorbereiding en planning van de staalnames?</p>

        <p>Ontdek dan hoe de software van GEO-Desk, het werk van labomedewerkers en staalnemers vergemakkelijkt.</p>
        <p>&nbsp;</p>


        <div class="row">
            <div class="col-md-3 ">
                <div class="flex flex-col boxed-icon">
                    <div class="img">
                        <div><img src="/assets/img/icon/Icoon_labo.jpg" alt="plaatje" align='center'></div>
                    </div>
                    <div class="subtitle">Voor labo’s in bodem- en wateronderzoek</div>
                    <div class="content">
                        Ontwikkeld om het proces van bodem- en waterstaalnames te vereenvoudigen.
                    </div>
                </div>
            </div>
            <div class="col-md-3 ">
                <div class="flex flex-col boxed-icon">
                    <div class="img">
                        <div >
                            <img src="/assets/img/icon/Icoon_wekker.jpg" alt="plaatje" align='center'>
                        </div>
                    </div>
                    <div class="subtitle">Efficiënter samenwerken met staalnemers</div>
                    <div class="content">
                        Geen overtypwerk meer. Win kostbare tijd door de staalgegevens digitaal te ontvangen.
                    </div>
                </div>
            </div>
            <div class="col-md-3 ">
                <div class="flex flex-col boxed-icon">
                    <div class="img">
                        <div><img src="/assets/img/icon/Icoon_kalender.jpg" alt="plaatje" align='center'></div>
                    </div>
                    <div class="subtitle">Een goed overzicht, op elk moment</div>
                    <div class="content">
                       Van staalgegevens, kaartmateriaal tot en met de planning van de staalnemers.
                    </div>
                </div>
            </div>
            <div class="col-md-3 ">
                <div class="flex flex-col boxed-icon">
                    <div class="img">
                        <div><img src="/assets/img/icon/Icoon_integratie_vlm.jpg" alt="plaatje" align='center'></div>
                    </div>
                    <div class="subtitle">Integratie met VLM (SNapp en SMIL)</div>
                    <div class="content">
                        Voortaan één oplossing om aanvragen te ontvangen en gegevens uit te wisselen.
                    </div>
                </div>
            </div>

        </div>




        <p>&nbsp;</p>

        <h2>GEO-Desk, hoe werkt het dan precies?</h2>
        <p>&nbsp;</p>

        <div class="row">
            <div class="col-md-6 ">
                <img src="/assets/img/GEODesk/screen1_GEODesk.jpg" alt="plaatje" width="400px">
            </div>
            <div class="col-md-6 ">
                <p><strong>Bij de voorbereiding</strong></p>
                <p>Als labo kun je via de GEO-Desk-oplossing de staalnames aanmaken en inplannen, meteen met het bijhorend kaartmateriaal. En dat volledig digitaal.</p>
                <p>De staalnames worden vervolgens toegewezen aan de beschikbare staalnemers, elk met een actuele status om opvolging te vergemakkelijken.</p>

            </div>
        </div>

        <p>&nbsp;</p><p>&nbsp;</p>

        <div class="row">
            <div class="col-md-6 ">
                <p><font size="+1,5"><strong>Tijdens een staalname</strong></font></p>
                <p>Dankzij de app van GEO-Desk heeft een staalnemer meteen inzicht in alle toegewezen staalnames en de informatie die hij of zij ter plaatse nodig heeft.</p>
                <p>Zo kan een staalnemer niet alleen vooraf een eigen planning maken, maar ook ter plaatse alle meetgegevens digitaal registreren in plaats van op papier.</p>
                <p>&nbsp;</p>
            </div>
            <div class="col-md-6 ">
                <img src="/assets/img/GEODesk/screen2_GEODesk.jpg" alt="plaatje" width="400px">
            </div>
        </div>

        <p>&nbsp;</p><p>&nbsp;</p>

        <div class="row">
            <div class="col-md-6 ">
                <img src="/assets/img/GEODesk/screen3_GEODesk.jpg" alt="plaatje" width="400px">
            </div>
            <div class="col-md-6 ">
                <p><font size="+1,5"><strong>De naverwerking</strong></font></p>
                <p>Het is niet meer nodig voor labo’s om alle staalgegevens over te typen. Door het gebruik van GEO-Desk stromen voortaan alle meetgegevens rechtstreeks door.</p>
                <p>Gedaan met het uitzoeken van de uitgevoerde analyses of bijhorende locatie coördinaten. Via GEO-Desk ontvang je alles in een duidelijk overzicht.</p>
                <p>&nbsp;</p>
            </div>
        </div>



        <h2 class="center m-3">De voordelen op een rijtje</h2>




        <div class="row m-5 ">
            <div class="col-md-4 ">
                <div class="flex flex-col boxed-icon">
                    <div class="img">
                        <div><img src="/assets/img/icon/Icoon_boekentas.jpg" alt="plaatje" align='center'></div>
                    </div>
                    <div class="content">
                        <ul class="voordelen"> <!-- Unordered list - bolletjes ervoor -->
                        <li>Rechtstreekse integratie met VLM, SNapp en SMIL</li>
                        <li>Mogelijkheid om meer staalnames te verwerken</li>
                        <li>Foutmarge in staalgegevens wordt drastisch verkleind</li>
                        <li>Statistische inzichten door te beschikken over aanvullende staalgegevens</li>
                     </ul>            </div>
                </div>
            </div>
            <div class="col-md-4 ">
                <div class="flex flex-col boxed-icon">
                    <div class="img">
                        <div ><img src="/assets/img/icon/Icoon_wekker.jpg" alt="plaatje" align='center'></div>
                    </div>
                    <div class="content">
                        <ul class="voordelen"> <!-- Unordered list - bolletjes ervoor -->
                        <li>Efficiëntere manier om staalnames te verwerken</li>
                        <li>Tijdwinst door het digitaal ontvangen van staalgegevens</li>
                        <li>Kaartmateriaal wordt automatisch aangemaakt</li>
                        <li>Sneller kunnen ingrijpen dankzij actueel overzicht</li>
                      </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 ">
                <div class="flex flex-col boxed-icon">
                    <div class="img">
                        <div><img src="/assets/img/icon/Icoon_mail.jpg" alt="plaatje" align='center'></div>
                    </div>
                    <div class="content">
                       <ul class="voordelen"> <!-- Unordered list - bolletjes ervoor -->
                        <li>Sneller toegang tot de informatie van de staalnames</li>
                        <li>Mogelijkheid om vooraf eigen planning te maken</li>
                        <li>Eenvoudigere manier om de staalgegevens te registreren</li>
                        <li>Conform vereisten van controlerende instanties</li>
                      </ul>
                    </div>
                </div>
            </div>


        </div>



        <h2 class="center m-3">Beschik jij over een uniek staalname- of labo-proces?</h2>

        <div class="image-center">
            <img src="/assets/img/GEODesk/screen4_GEODesk.png" alt="plaatje" width="400px">
        </div>
        <p>Geen zorgen. De GEO-Desk-oplossing is ontwikkeld om met unieke processen om te kunnen gaan. Met meer dan 20 jaar labo-ervaring weten we dat ieder proces anders is.</p>
        <p>Nadat we de workflow van jouw labo in kaart hebben gebracht, zorgen we ervoor dat GEO-Desk werkt naargelang jouw labo-proces.</p>
        <p>&nbsp;</p>


    <h6>Benieuwd naar wat we voor jou kunnen doen?</h6>

    <contact-button></contact-button>




        <!--

        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <table width="100%" border="2">
        <tr>
        <td ROWSPAN="3"><img src="/assets/img/GEODesk/screen1_GEODesk.jpg" alt="plaatje" width="300px"></td>
        <td align="center" valign="center" width="120" <p><font size="+1,5" ><strong>Bij de voorbereiding.</strong></font></p></td>
        </tr>

        <tr>
        <td align="center" valign="center" width="90"<p><font size="+1" >Als labo kun je via de GEO-Desk-oplossing de staalnames aanmaken en inplannen, meteen met het bijhorend kaartmateriaal. En dat volledig digitaal.</font></p></td>
        </tr>
        <tr>
        <td align="center" valign="center" width="90"<p><font size="+1" >De staalnames worden vervolgens toegewezen aan de beschikbare staalnemers, elk met een actuele status om opvolging te vergemakkelijken.</font></p></td>
        </tr>
        </table>

        <p>&nbsp;</p>

        <h6>Benieuwd naar wat we voor jou kunnen doen?</h6>


                 <div class="option-item">
                  <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Contacteer ons<span></span></a>
                 </div>

        <p>&nbsp;</p>
        -->
    `,
    testimonials : [
        {
            clientImg: 'assets/img/testimonials/logo_GEODesk_50 px.png',
            paragraphText: 'Dankzij GEO-Desk verlopen de staalnames veel vlotter dan voordien. De  software zorgt immers grotendeels voor een automatische validatie van looppatronen. Dit diende in het verleden manueel te gebeuren. We merken bovendien dat de planningen voor de staalnemers, het koppelen van de gps-gegevens aan de staalnemer en het aanmaken van kaartmateriaal een groot voordeel zijn tijdens de nitraatcampagne. ',
            clientName: '',
            clientDesignation: 'Kwaliteitsverantwoordelijke'
        },
        {
            clientImg: 'assets/img/testimonials/logo_GEODesk_50 px.png',
            paragraphText: 'Voorheen werd het kaartmateriaal manueel aangemaakt, een werk van lange adem. Dankzij GEO-Desk kunnen we sneller en efficiënter werken. De software is ook eenvoudig in gebruik, waardoor we minder tijd verliezen en zaken kunnen afwerken binnen de aanvaardbare termijnen voor de gebruiker. Bovendien kunnen we bij GEO-Desk terecht voor een snelle, vlotte en kwaliteitsvolle ondersteuning, op elk moment van de dag!',
            clientName: '',
            clientDesignation: 'Project Coördinator'
        },
        {
            clientImg: 'assets/img/testimonials/logo_GEODesk_50 px.png',
            paragraphText: 'We  constateren een enorme tijdswinst met GEO-Desk, doordat kaarten niet meer handmatig aangemaakt moeten worden. Ook de opmaak van planningen per staalnemer is een sterke troef.',
            clientName: '',
            clientDesignation: 'Expert monstername/planning'
        },
        {
            clientImg: 'assets/img/testimonials/logo_GEODesk_50 px.png',
            paragraphText: 'Door GEO-Desk wordt er korter op de bal gespeeld. Zo gaat er minder tijd verloren tussen aanvraag en staalname. Deze werkwijze maakt het voor ons mogelijk om dagplanningen te maken op basis van geografische ligging. Daarbij kunnen bijhorend kaartmateriaal en etiketten zelf afgedrukt worden. Tijdens de staalnames zelf worden er bovendien minder verloren kilometers gereden. Dit zorgt voor tijdswinst en een hogere capaciteit van staalnames.  ',
            clientName: '',
            clientDesignation: 'Staalnemer in verschillende labo’s'
        }



    ]

}
