import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-medewerkers',
    templateUrl: './home-medewerkers.component.html',
    styleUrls: ['./home-medewerkers.component.scss']
})
export class HomeMedewerkersComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Ons team",
             title: 'Over ons',
             paragraphText: 'Systemworks bv werd opgericht in 2005. Ondertussen berusten we op een ervaring van meer dan 20 jaar. Als enthousiast klein team zijn we volledig op elkaar afgestemd en is er een persoonlijker contact met onze klanten. Dit resulteert in een meer betrokken en productieve samenwerking. '
        }
    ]
    singleScientistBox: MedewerkersLijstBoxContent[] = [
        {
            img: 'assets/img/scientist/Pieter.jpg',
            title: 'Pieter Degraeuwe',
            designation: 'Zaakvoerder',
            facebookLink: '#',
            facebookIcon: 'bx bxl-facebook',
            twitterLink: '#',
            twitterIcon: 'bx bxl-twitter',
            instagramLink: '#',
            instagramIcon: 'bx bxl-instagram',
            linkedinLink: '#',
            linkedinIcon: 'bx bxl-linkedin'
        },
         {
            img: 'assets/img/scientist/Katrien.jpg',
            title: 'Katrien D\'Hooghe',
            designation: 'Quality assurance consultant',
            facebookLink: '#',
            facebookIcon: 'bx bxl-facebook',
            twitterLink: '#',
            twitterIcon: 'bx bxl-twitter',
            instagramLink: '#',
            instagramIcon: 'bx bxl-instagram',
            linkedinLink: '#',
            linkedinIcon: 'bx bxl-linkedin'
         },
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class MedewerkersLijstBoxContent {
    img: string;
    title: string;
    designation: string;
    facebookLink: string;
    facebookIcon: string;
    twitterLink: string;
    twitterIcon: string;
    instagramLink: string;
    instagramIcon: string;
    linkedinLink: string;
    linkedinIcon: string;
}
