import {Blog, KATRIEN, PIETER} from "./blog";

// postImg: 'assets/img/blog/img1.jpg',
//     postTitle: 'Six Ways to Make Smarter Decisions',
//     postLink: 'blog-details',
//     postDate: 'April 30, 2024',
//     postAuthorImage: 'assets/img/user1.jpg',
//     postAuthorName: 'Alex Morgan'


export const testBlog2: Blog = {
    id: "2",
    title: 'Productie software laboratorium',
    subTitle: 'Software voor een laboratorium gaat in productie',
    author: PIETER,
    date: "1 augustus 2016",
    image: 'assets/img/blog/Blog_aug2016_1_700x600.jpg',
    content: `

                        <h3>Vraag</h3>
                        <p>Het labo analyseert allerlei stalen zoals water, voedingsstoffen, putwater, bodem, etc. en bepaalt a.h.v. van wettelijke normen of deze al dan niet geschikt zijn.
                           De software (een unix terminal applicatie) voldeed helemaal niet meer aan de huidige vereisten, en was dus aan vervanging toe.
                           Alle aspecten van het hele proces, startende van de aanvraag, in ontvangst nemen van de stalen, analyse en naverwerking, controle t.o.v de norm, rapportering (verslag) werden opgenomen om zo een coherente toepassing te bouwen.</p>

                        <blockquote class="wp-block-quote">
                            <p>Software is great combination between artistry and engineering.</p>

                        <cite>Bill Gates</cite>

                        </blockquote>


                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog2_aug 2016/Blog 2_Labsoft 1_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog2_aug 2016/Blog 2_Labsoft 2_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog2_aug 2016/Blog 2_Labsoft 3_750x650.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>

                        <!--
                        <h3>Four major elements that we offer:</h3>

                        <ul class="features-list">
                            <li><i class='bx bx-badge-check'></i> Scientific skills for getting a better result</li>
                            <li><i class='bx bx-badge-check'></i> Communication skills to getting in touch</li>
                            <li><i class='bx bx-badge-check'></i> A career overview opportunity available</li>
                            <li><i class='bx bx-badge-check'></i> A good work environment for work</li>
                        </ul>
                        -->

                        <h3>Oplossing</h3>
                        <h5>Ingave van aanvragen en stalen</h5>

                        <p>Het ingeven van alle gegevens mbt de aanvragen, stalen, klangegevens, betrokken partijen, etc. moet op een zo efficiënt mogelijke manier gebeuren. Ook import in bulk (dmv een Excelbestand) wordt gesupporteerd.
                           Logischerwijs wordt ook het gebruik van barcodes gebruikt waar mogelijk.</p>

                        <h5>Automatische generatie van besluiten en verslagen</h5>

                        <p>Belangrijk was dat er vele zaken konden geautomatiseerd worden. Zo moest het mogelijk zijn dat er op basis van resultaten en een gekende (wettelijke) norm, automatisch één of meerdere besluiten konden getrokken worden. Na finale goedkeuring door de verantwoordelijke, kunnen deze besluiten, al dan niet in de vorm van een te configureren verslag, verstuurd worden naar de klant.</p>

                        <h5>Gebruik van Excel voor complexe nabewerkingen</h5>

                        <p>Een bijkomende moeilijkheid was het feit dat bepaalde resultaten vaak nog nabewerkingen / berekeningen nodig hebben. Vroeger werd dit steeds manueel gedaan, via een set van Excel bestanden, maar dit had tot gevolg dat er telkens een pak overtypwerk zat, met mogelijke menselijke fouten tot gevolg.
                           Aangezien deze Excel bestanden een pak aan knowhow, en soms ook complexe berekeningen bevatten, was het niet evident, noch kosten-efficiënt om deze logica zomaar te herprogrammeren.
                           Bovendien moet deze logca makkelijk kunnen worden aangepast door het personeel (waar een goede kennis van Excel aanwezig is), waardoor het behoud van deze Excel bestanden bijna noodzakelijk was.
                           We zijn er in geslaagd om deze nabewerkingen via excelbestanden uit te voeren, waarbij het uiteindelijke resultaat door onze software kan opgeslagen worden en dit zonder manuele tussenkomst.</p>



                        <!--
                        <h3>The rise of marketing and why you need it</h3>
                        -->

                        <p>Heb je nog vragen of nood aan persoonlijk advies? Contacteer ons.</p>
                        <div class="option-item">
                           <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Contacteer ons<span></span></a>
                        </div>


    `

}
