import { Component, OnInit } from '@angular/core';
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";

@Component({
    standalone : true,
    imports: [RouterModule, CommonModule],

    selector: 'app-consultancy-services-page',
    templateUrl: './consultancy-services-page.component.html',
    styleUrls: ['./consultancy-services-page.component.scss']
})
export class ConsultancyServicesPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Consultancy services',
            subTitle: '',
        }
    ]
    servicesDetailsImage: DetailsImage[] = [
        // {
        //     img: 'assets/img/services/services-details1.jpg'
        // }
    ]
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'Consulting',
            title1: '',
            paragraphText1: 'Het grootste deel van onze activiteiten is bedrijven en overheden bijstaan in de ontwikkeling van toepassingen, zowel back-end, front-end(web) als mobile apps. Schouder aan schouder versterken we uw teams en zorgen ervoor dat uw bedrijf mee kan evolueren in een steeds veranderende wereld. Benieuwd naar wat we voor jou kunnen doen?',

            paragraphText2: '',
            title2: 'Consultancy voor jouw bedrijf?',
            title3: 'Sectoren',
            title4: 'Onze toolbox',
            img1: 'assets/img/services/cons_img 2_750x425.jpg',
            img2: 'assets/img/services/cons_img_1_750x425.jpg'
        }
    ]
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'Software Development'
        },
        {
            title: 'Architecture'
        },
        {
            title: 'Data Integration'
        },
        {
            title: 'Quality Assurance'
        },
        {
            title: 'Statistics'
        },
        {
            title: '...'
        }
    ]
    servicesDetailsDescApplication: Application[] = [
        {
            title: 'Overheid',
            icon: 'flaticon-factory'
        },
        {
            title: 'Onderwijs',
            icon: 'flaticon-hospital'
        },
        {
            title: 'Telecommunicatie',
            icon: 'flaticon-tracking'
        },
        {
            title: 'Financiële sector',
            icon: 'flaticon-investment'
        },
        {
            title: 'Laboratoria',
            icon: 'flaticon-house'
        },
        {
            title: 'Andere sectoren',
            icon: 'flaticon-order'
        }
    ]
    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: 'JavaScript'
        },
        {
            title: 'SQL/PLSQL '
        },
        {
            title: 'Java'
        },
        {
            title: 'Hibernate / JPA '
        },
        {
            title: 'TDD'
        },
        {
            title: 'Angular 2 - 15 '
        }
    ]

    sidebarServicesList: ServicesList[] = [
        {
            title: 'AI & ML Development',
            link: 'services-details'
        },
        {
            title: 'Data Analytics',
            link: 'services-details'
        },
        {
            title: 'Data Science',
            link: 'services-details'
        },
        {
            title: 'Artificial Intelligence',
            link: 'services-details'
        },
        {
            title: 'Data Visualization',
            link: 'services-details'
        }
    ]
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: 'PDF Download',
            icon: 'bx bxs-file-pdf',
            link: 'services-details'
        },
        {
            title: 'Services Details.txt',
            icon: 'bx bxs-file-txt',
            link: 'services-details'
        }
    ]
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Phone',
            subTitle: '+2145 354 5413'
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: 'New York, USA'
        },
        {
            icon: 'bx bx-envelope',
            title: 'Email',
            subTitle: 'hello&#64;wilo.com'
        }
    ]

}
class pageTitle {
    title : string;
    subTitle : string;
}
class DetailsImage {
    img : string;
}
class DetailsDesc {
    subTitle : string;
    title1 : string;
    title2 : string;
    title3 : string;
    title4 : string;
    paragraphText1 : string;
    paragraphText2 : string;
    img1 : string;
    img2 : string;
}
class ImportantFacts {
    title : string;
}
class Application {
    title : string;
    icon: string;
}
class TechnologiesFeatures {
    title : string;
}

class ServicesList {
    title : string;
    link : string;
}
class DownloadFile {
    title : string;
    icon : string;
    link : string;
}
class ContactInfo {
    icon : string;
    title : string;
    subTitle : string;
}
