<section class="blog-area pt-100 pb-70 bg-f1f8fb">
    <div class="container">

        <div class="section-title" >
            <span class="sub-title"><img src="/assets/img/icon/Blaadje_klein_47 px.png" alt="image"> {{page.blog.sectionTitle.subTitle}}</span>
            <h2>{{page.blog.sectionTitle.title}}</h2>
            <p *ngIf="page.blog.sectionTitle?.paragraphText">{{page.blog.sectionTitle.paragraphText}}</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6" *ngFor="let blog of visibleBlogs">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog/{{blog.id}}">
                            <img [src]="blog.image" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <ul class="post-meta d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img [src]="blog.author.avatar" class="rounded-circle" alt="image">
                                    <span>{{blog.author.name}}</span>
                                </div>
                            </li>
                            <li>
                                <i class='flaticon-calendar'></i> {{blog.date}}
                            </li>
                        </ul>
                        <h3><a routerLink="/blog/{{blog.id}}">{{blog.title}}</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!--    <div class="vector-shape1" data-speed="0.06" data-revert="true"><img src="/assets/img/shape/vector-shape1.png" alt="image"></div>-->
<!--    <div class="vector-shape2" data-speed="0.06" data-revert="true"><img src="/assets/img/shape/vector-shape2.png" alt="image"></div>-->
</section>
