import {Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewEncapsulation} from '@angular/core';
import {Blog} from "../../../data/blog/blog";
import {testBlog} from "../../../data/blog/testblog";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {BlogService} from "../../../service/blog.service";
import {map, Observable} from "rxjs";
import {AsyncPipe, NgIf} from "@angular/common";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";


@Component({
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    selector: 'app-blog-details-page',
    templateUrl: './blog-details-page.component.html',
    imports: [
        RouterLink,
        AsyncPipe,
        NgIf
    ],
    styleUrls: ['./blog-details-page.component.scss']
})
export class BlogDetailsPageComponent implements OnInit {

    blogObs : Observable<Blog>

    sanitizedBlogContent : Observable<SafeHtml>

    constructor(_router : Router ,_route : ActivatedRoute, private _blogService : BlogService, private _sanitizer : DomSanitizer) {
        this.blogObs = _route.data.pipe(map(p => {
            let blog = p['blog'];
            return blog;
        }))

        this.sanitizedBlogContent = this.blogObs.pipe(map(b => {
            return this._sanitizer.bypassSecurityTrustHtml(b.content);
        }))

    }

    ngOnInit(): void {
    }

}
