import {Component, CUSTOM_ELEMENTS_SCHEMA, Input} from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-readmore-button',
    templateUrl: './readmore-button.component.html',
    styleUrl: './readmore-button.component.scss'
})
export class ReadmoreButtonComponent {

    @Input()
    url : string
}
