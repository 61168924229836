//Eerst in wordt geschreven, daarna copy paste in   https://wordtohtml.net/

export const algemenevoorwaarden = {
    title: 'Algemene voorwaarden',
    content : `

<p><strong>Artikel 1. Toepassing algemene voorwaarden</strong></p>
<p>1.1 &nbsp;Deze algemene verkoopsvoorwaarden gelden op alle aanbiedingen en dienstverleningen (waarin begrepen ontwerpen, web ontwikkelingen, online marketing diensten, seo- en zoekmachine-optimalisatie-diensten, &ldquo;conci&euml;rge&rdquo; diensten, abonnementsformules maar ook verhuur, verkoop en onderhoud van apparatuur) van en overeenkomsten met Systemworks, niet- tegenstaande strijdige bepalingen vermeld op documenten van de klant. Deze algemene voorwaarden zijn eveneens van toepassing op alle occa- sionele opdrachten en prestaties van Systemworks. Door beroep te doen op of gebruik te maken van een dienst van Systemworks, en aldus door het plaatsen van een bestelling erkent de klant dat hij een exemplaar van deze algemene voorwaarden van Systemworks heeft ontvangen en aanvaardt op onherroepelijke wijze de toepassing ervan bij uitsluiting van alle andere.</p>
<p>&nbsp;</p>
<p>1.2 &nbsp;Deze algemene verkoopsvoorwaarden kunnen op elk ogenblik door Systemworks gewijzigd worden. Aanpassingen treden in voege vanaf de tweede maand volgend op de maand waarin kennisgeving werd gedaan van de aanpassing. Indien de klant deze aanpassingen niet aanvaardt, kan hij het contract opzeggen door een opzegging per aangetekend schrijven te zenden aan Systemworks binnen een termijn van 1 maand na de datum van kennisgeving. In voorkomend geval neemt de overeenkomst een einde op het ogenblik dat de gewijzigde voorwaarden zouden ingaan.</p>
<p>&nbsp;</p>
<p>1.3 &nbsp;Deze algemene verkoopsvoorwaarden herroepen en vervangen alle voorgaande, waarbij de versie van toepassing steeds raadpleegbaar is op de website van Systemworks.</p>
<p>​</p>
<p><strong>Artikel 2. Aanbiedingen en offertes &ndash; bestellingen</strong></p>
<p>2.1 Alle aanbiedingen, offertes, prijsopgaven en financi&euml;le voorstellen van Systemworks zijn vrijblijvend en worden uitsluitend overgemaakt ten titel van inlichting tot op het ogenblik van de aanvaarding door de klant. De bestelling door de klant komt tot stand wanneer deze de offerte ongewijzigd binnen de acht dagen voor akkoord ondertekent en terugstuurt aan Systemworks. Elke bestelling door de klant is bindend voor de klant. Bestellingen van de klant zijn onherroepelijk. De klant wordt geacht de eigenschappen, karakteristieken en dergelijke van het goed en/of de dienst te kennen en kiest het type materieel en/of dienst in functie van zijn behoeften en onder zijn uitsluitende verantwoordelijkheid. De bestelling vervangt alle eerder afgesloten en/of mondelinge akkoorden. De bestelling wordt uitgevoerd vanaf ontvangst van het voorschot.</p>
<p>​</p>
<p><strong>Artikel 3. Annulatie van de bestelling</strong></p>
<p>3.1 &nbsp;Systemworks behoudt zich het recht voor om een bestelling geheel of gedeel- telijk te weigeren, zelfs indien de bestelling gebaseerd is op een van Systemworks&rsquo;s uitgaande prijsoffertes. Indien Systemworks een bestelling slechts ge- deeltelijk aanvaardt, dient zij de klant hiervan in kennis te stellen en wordt de klant geacht hiermede in te stemmen behoudens aangetekend schrijven, houdende herroeping van zijn gehele bestelling, binnen de 5 werkdagen na verzending van voormelde kennisgeving. Desgevallend zal het gebruikmaken van de dienst en/of het in ontvangst nemen van het goed minstens gelden ten titel van aanvaarding van het contract en van deze algemene verkoopsvoorwaarden.</p>
<p>&nbsp;</p>
<p>3.2 &nbsp;De annulering van een bestelling door de klant is mogelijk zolang Systemworks haar werkzaamheden met betrekking tot de bestelling nog niet heeft aangevat of zolang er nog geen goederen geleverd zijn; steeds mits betaling van een opzegvergoeding van 40% van de overeengekomen prijs, met een minimum van 500 EUR.</p>
<p>​</p>
<p><strong>Artikel 4. Levering &ndash; oplevering</strong></p>
<p>4.1 &nbsp;De aanvaarde bestelling zal naar best vermogen binnen de in het contract voorziene termijn worden geleverd, en bij gebreke, binnen een redelijke termijn. Opgegeven levering- of opleveringstermijnen zijn louter indicatief en maken geen resultaats verbintenis uit in hoofde van Systemworks. Vertraging in de levering of oplevering geeft de klant geen recht op schadevergoeding of prijsvermindering noch op ontbinding van de overeenkomst.</p>
<p>&nbsp;</p>
<p>4.2 &nbsp;Indien de partijen uitdrukkelijk een leveringstermijn zijn overeengeko- men, wordt deze termijn verlengd indien de klant in gebreke blijft om de vereiste informatie, documenten, originelen, beelden (tijdig) over te maken en de finale proeven (tijdig) te aanvaarden, of indien de klant bijkomende bestellingen plaatst die geen betrekking hebben op bestelling waarvoor een leveringstermijn is overeengekomen.</p>
<p>&nbsp;</p>
<p>4.3 &nbsp;Indien Systemworks, ten gevolge van enige oorzaak onafhankelijk van haar wil of ingevolge overmacht, in de onmogelijkheid is om een aanvaarde bestelling of diensten uit te voeren of dit te doen binnen de gestelde levering- of opleveringstermijn, kan zij, bij eenvoudige kennisgeving, de levering- of opleveringstermijn verlengen of het contract ontbinden zonder enige schadevergoeding verschuldigd te zijn.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 5. Risico</strong></p>
<p>5.1 Alle goederen die toebehoren aan de klant en zich bij Systemworks bevinden (op het gevestigd adres van de onderneming of in haar collocatie-ruimten), worden er bewaard op risico van de klant.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 6. Waarborg</strong></p>
<p>6.1 Indien het door Systemworks geleverd goed of opgeleverde dienst (het op een live-omgeving plaatsen van een website, web applicatie of andere online-toepassing) bij de levering of oplevering zichtbare gebreken vertoont of wezenlijk niet-conform is aan de bestelling, dient de klant dit op dat tijdstip te laten acteren op de leveringsbon, de factuur of op het vervoerdocument. Binnen de 5 werkdagen na (op)levering dient de klant Systemworks per aangetekend schrijven op de hoogte te stellen van de zichtbare gebreken of niet-conformiteit met afschrift van voormeld document waarop acte. Na afloop van die termijn wordt het goed of de opgeleverde dienst beschouwd als definitief door de klant aanvaard.</p>
<p>&nbsp;</p>
<p>6.2 Systemworks verstrekt geen enkele waarborg noch vrijwaring voor verborgen gebreken op geleverde goederen. Op geleverde diensten geldt een waarborgperiode van 3 maanden na oplevering, doch geen enkele vrijwaring voor verborgen gebreken. De waarborg beperkt zich tevens tot de uitgewerkte technische aspecten van een project en moeten van die aard zijn dat gebruik van de website, web applicatie of andere on- line toepassing erdoor verhinderd wordt. Op andere aspecten (visuele, gebruiksgemak e.a.) die het gebruik niet in de weg staan wordt geen enkele waarborg verstreken.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 7. Betalingsmodaliteiten</strong></p>
<p>7.1 &nbsp;Tenzij andersluidende bepaling, zijn de prijzen en tarieven deze die van toepassing zijn op het ogenblik waarop de</p>
<p>prijsofferte aanvaard wordt en het contract tot stand komt.</p>
<p>&nbsp;</p>
<p>7.2 &nbsp;De prijs is onherleidbaar en kan in het bijzonder niet worden ingekort wanneer een onderdeel van de dienstverlening, geheel of tijdelijk, niet kan worden uitgevoerd.</p>
<p>&nbsp;</p>
<p>7.3 &nbsp;Bij elke bestelling dient de klant een voorschot te betalen van 40% van het factuurbedrag. De dienstverlening wordt pas opgestart na de betaling van deze voorschotfactuur. Voor projecten boven de 50 man- dagen wordt een milestone* factuur opgemaakt bij validatie van de ontwikkelingen van de helft van de ontwikkeling door de klant, deze bedraagt 30% van de totale kostprijs. De resterende 30% (of 60%) wordt gefactureerd bij oplevering. De betaling van de slotfactuur dient steeds vooraf te gaan aan de oplevering op een live-omgeving.</p>
<p>&nbsp;</p>
<p>7.4 &nbsp;Behoudens andersluidende bepaling, wordt vooraf aan elke dienstverle- ning, factureringsperiode of levering, gefactureerd en zijn alle facturen, behoudens anders overeengekomen of vermeld op de factuur, contant betaalbaar op de op de factuur vermelde bankrekening van Systemworks. Bij gebreke aan aangetekend schrijven, houdende protest der factuur, binnen de 14 dagen na factuurdatum, wordt de factuur geacht definitief aanvaard te zijn.</p>
<p>&nbsp;</p>
<p>7.5 Iedere door Systemworks ontvangen betaling zal eerst worden aangerekend op de vervallen interesten en schadevergoedingen, vervolgens op de hoofdsom van de eerst vervallen factuur.</p>
<p>&nbsp;</p>
<p>7.6 Is de factuur op de vervaldag onbetaald (valuta rekening Systemworks geldt), dan wordt het bedrag automatisch en van rechtswege en zonder enige voorafgaandelijke aanmaning, verhoogd ten titel van forfaitaire schade- vergoeding met respectievelijk &euro; 25 voor een hoofdsom minder dan of gelijk aan &euro; 250, &euro; 50 voor een hoofdsom tussen &euro; 250 en &euro; 500, en 15% voor een hoofdsom van &euro; 500 of meer per factuur, met maximum van &euro; 5.000 per factuur, alsmede ten titel van nalatigheidinteresten met 1% per maand, waarbij een gedeelte van een maand geldt als een gehele maand, tot de dag van de algehele betaling, onverminderd haar recht op vergoeding van de gerechtskosten en op schadeloosstelling van alle relevante invorderingskosten.</p>
<p>&nbsp;</p>
<p>7.7 Vervallen interesten, die tenminste voor een geheel jaar verschuldigd zijn, brengen op hun beurt interesten aan dezelfde interestvoet op.</p>
<p>&nbsp;</p>
<p>7.8 In geval van enig onbetaald saldo, is Systemworks gerechtigd om elke verdere levering en/of prestatie tot aan integrale aanzuivering op te schorten en afhankelijk te stellen van voorafbetaling van de te leveren goederen en/of prestaties, van een garantie en/of van domiciliering, waarbij de klant zich ertoe verbindt om uiterlijk binnen de 8 werkdagen na het eerste verzoek van Systemworks hieraan te voldoen, bij gebreke waaraan Systemworks gerechtigd is het contract te be&euml;indigen ten laste en voor risico van de klant. Bovendien is de waarborg alsdan van rechtswege opgeheven, zonder dat deze opheffing de waarborgperiode verlengt.</p>
<p>&nbsp;</p>
<p>7.9 In geval van enig onbetaald saldo, wordt elke factuur of vordering, zelfs niet-vervallen, onmiddellijk opeisbaar.</p>
<p>&nbsp;</p>
<p>7.10 De dienstverlening wordt gefactureerd ongeacht het gebruik ervan.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 8. Klachten &ndash; protest van de factuur</strong></p>
<p>8.1 Elk protest dient per aangetekende brief aan Systemworks te worden bezorgd binnen een termijn van 8 dagen. Voor klachten of betwistingen met betrekking tot de geleverde goederen of diensten vangt de termijn aan daags na oplevering. Met betrekking tot de factuur begint de termijn op de factuurdatum. Bij gebreke aan tijdig protest zijn de diensten/facturen definitief aanvaard en is betaling verschuldigd.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 9. Aansprakelijkheid</strong></p>
<p>9.1 Elke aansprakelijkheid van Systemworks is steeds, zelfs bij zware fout doch behoudens opzet of bedrog, voor eender welke schade veroorzaakt door het door haar geleverd goed en/of door de geleverde dienst, met inbegrip van nalatigheid en/of omissie beperkt tot 10.000 EUR per schadegeval en tot 25.000 EUR voor alle schadegevallen samen in een bepaald kalenderjaar.</p>
<p>&nbsp;</p>
<p>9.2 De aansprakelijkheid van Systemworks voor eender welke genots-, winstderving en/of indirecte schade (waaronder niet &ndash;beperking begrepen, verlies van winst, verlies van omzet, verlies van cli&euml;nteel, verlies of beschadi- ging van data en verlies van kans) is steeds, zelfs bij zware fout doch behoudens opzet of bedrog, uitgesloten.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 10. Installatie &ndash; Oplevering</strong></p>
<p>10.1 Behoudens andersluidende bepaling, omvat de dienstverlening en/of de verkoop van goederen niet de installatie of oplevering ervan. De klant verwezenlijkt deze zelf, op zijn kosten en onder zijn uitsluitende verantwoordelijkheid.</p>
<p>&nbsp;</p>
<p>10.2 In de installatie prijs is enkel begrepen, met uitsluiting van alle andere, een eenmalige installatie van de door Systemworks geleverde goederen en/of diensten zonder een verbinding tot stand te brengen met enig ander goed dat niet het voorwerp van onderhavig contract uitmaakt. Een nieu- we installatie kan slechts na betaling van de kosten &eacute;n na ondertekening van een contractwijziging.</p>
<p>&nbsp;</p>
<p>10.3 Behoudens uitdrukkelijk protest door de klant binnen de 5 werkdagen, per aangetekende zending, na de installatie door Systemworks, wordt geacht dat de dienst volgens de regels van de kunst wordt geleverd of ge&iuml;nstalleerd.</p>
<p>&nbsp;</p>
<p>10.4 Indien de installatie niet tot stand kan komen door een fout van de klant of van een derde, blijft de klant gehouden om alle facturen te voldoen.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 11. Intellectuele Eigendomsrechten</strong></p>
<p>11.1 Onder Intellectuele Eigendomsrechten wordt verstaan: alle intellectuele en industri&euml;le eigendomsrechten (ongeacht of deze geregistreerd zijn of niet), met inbegrip van maar niet beperkt tot auteursrechten, naburige rechten, merken, tekeningen en modellen of aanvragen tot registratie als tekening of model, octrooien, aanvragen tot octrooien, alsmede rechten op databanken en computerprogramma&rsquo;s.</p>
<p>&nbsp;</p>
<p>11.2 Beide partijen aanvaarden dat het concept van een website, web applicatie of andere online toepassing (hiermee bedoeld maar niet beperkend de opbouw van de schermen van de website, web applicatie of online toepassing, de wireframes, de hoofdnavigatie en basisfuncties) in beginsel niet zal worden beschermd door Intellectuele Eigendomsrechten. De klant kan dan ook een gelijkaardige opbouw terugvinden bij andere door Systemworks ontwikkelde sites.</p>
<p>&nbsp;</p>
<p>11.3 De Intellectuele Eigendomsrechten verbonden aan de verschillende SAAS (Software as a Service) diensten, Mits betaling van een licentievergoeding, bepaald in de offerte van Systemworks, en onder opschortende voorwaarde van de volledige betaling van deze vergoeding, verkrijgt de klant een niet exclusieve, niet-overdraagbare gebruikslicentie op deze software voor de duur van de betreffende dienstverlening. Het is de klant verboden om sublicenties aan derden toe te kennen, dan wel de software op enigerlei wijze aan derden ter beschikking te stellen, mee te delen, te gebruiken ten behoeve van derden of te commercialiseren.</p>
<p>&nbsp;</p>
<p>11.4 De klant zal de Intellectuele Eigendomsrechten van Systemworks te allen tijde respecteren en redelijke inspanningen leveren om die rechten te beschermen. De klant zal Systemworks onmiddellijk in kennis stellen van iedere inbreuk door derden op de Intellectuele Eigendomsrechten van Systemworks waarvan hij kennis neemt.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 12. Eigendomsbehoud</strong></p>
<p>12.1 De klant aanvaardt en erkent hierbij op onherroepelijke en bijzondere wijze dat het geleverd goed of de geleverde dienst (in geval van website, web applicatie of andere online toepassingsontwikkeling) eigendom van Systemworks blijft, in geval van aankoop, tot de koopprijs in hoofdsom volledig betaald is alsmede, in geval van ontwikkeling, zolang de betaling van de slotfactuur niet volledig betaald is. Tot zolang is de klant niet gerechtigd, op straffe van aansprakelijkheid, het goed aan derden in pand of borg te geven, of over te dragen en dient hij zich te verzetten tegen eventuele inbeslagname alsmede Systemworks hiervan per kerende in kennis te stellen.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 13. Hosting diensten</strong></p>
<p>13.1 De hosting diensten worden door Systemworks aan de klant verstrekt per jaar, tenzij anders bepaald, mits betaling door de klant van de verschuldigde vergoeding. De actuele prijslijst kan op eenvoudig verzoek van de klant bezorgd worden door Systemworks en wordt jaarlijks aangepast. Indien de klant deze dienst wenst op te zeggen, dient hij dit te doen door uiterlijk 1 maand voor het einde van het jaarcontract zijn opzeg aan Systemworks over te maken per aangetekende brief. Bij laattijdige opzeg zal de klant de vergoeding voor het volgende kalenderjaar verschuldigd zijn.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 14. Toegankelijkheid hosting &ndash; SAAS&nbsp;(Software as a Service) diensten</strong></p>
<p>14.1 Systemworks verbindt er zich toe om, binnen de technisch beperktheden en naar best vermogen, de in het contract vermelde dienstverlening, bij uitsluiting van alle andere, uit te voeren, houdende een middelenverbintenis. 14.2 Gelet op de technische beperkingen inherent aan de dienstverlening, kan noch de toegang tot, noch het gebruik van, noch de kwaliteit en/of kwantiteit/volume van de dienstverlening op ieder ogenblik gegarandeerd worden, behoudens andersluidende uitdrukkelijke en bijzondere vermelding in het contract.</p>
<p>&nbsp;</p>
<p>14.3 De in het contract vermelde dienst wordt, behoudens uitdrukkelijke en bijzondere vermelding in het contract, op limitatieve wijze en bij uitslui- ting van alle andere, gedefinieerd en afgelijnd in het gedetailleerde dossier van Systemworks welke op datum van ondertekening van het contract, op de dienst betrekking heeft. De klant erkent hierbij van dit gedetailleerd dossier een exemplaar te hebben ontvangen en deze te aanvaarden.</p>
<p>&nbsp;</p>
<p>14.4 Het contract wordt aangegaan onder de uitdrukkelijke ontbindende voorwaarde van normale beschikbaarheid van de betreffende dienst op de Belgische markt. Desgevallende ontbinding heeft geen enkel gevolg voor het voortbestaan van het contract met betrekking tot de andere daarin vermelde en beschikbare diensten. De klant is desgevallend niet gerechtigd op enige schadevergoeding.</p>
<p>&nbsp;</p>
<p>14.5 De klant erkent dat de dienstverlening onderhevig is aan sterke evoluties en wijzigingen in de informatica sector. De dienstverlening, zowel wat de aard, specificaties, kenmerken en/of omvang/volume van welke aard ook betreft, kan op elk ogenblik aangepast en/of gewijzigd wor- den aan de actuele stand van de algemeen gangbare technische normen zonder eender welke kennisgeving noch recht op schadevergoeding in hoofde van de klant. Desgevallend is Systemworks gerechtigd om, mits notifi- catie, de contractuele prijs met onmiddellijke ingang aan de gewijzigde dienstverlening aan te passen. Indien zulks een prijsverhoging van meer dan 10% (excl. BTW) tot gevolg heeft, is de klant gerechtigd om, binnen de 8 dagen na notificatie, op straffe van definitief verval van zijn recht, het betreffend contract te be&euml;indigen middels een aangetekend schrijven (datum van ontvangst geldt). De klant is desgevallend niet gerechtigd op enige schadevergoeding.</p>
<p>&nbsp;</p>
<p>14.6 Systemworks is gerechtigd om, op elk ogenblik en zonder eender welke ken- nisgeving, elke maatregel van inwendige orde en/of nuttig voor de continu&iuml;teit van de dienstverlening te nemen, waarin begrepen doch niet-limitatief: wijziging van toegangscodes, gebruikerstoegang en -naam, inbelnummers, login procedure, onderhoudswerken, infrastructuurwijzigingen, technische of procedurele wijzigingen. Eventuele directe of indirecte gevolgen en/of kosten die uit voornoemde maatregelen kunnen voortvloeien of die deze kunnen teweegbrengen voor de klant of met betrekking tot zijn interne infrastructuur, zijn uitsluitend ten laste van de klant zonder dat deze desgevallend gerechtigd is op welke schadevergoeding dan ook.</p>
<p>&nbsp;</p>
<p>14.7 In geval van onbeschikbaarheid van de dienst, dient de klant Systemworks aangetekend te verwittigen, waarna deze binnen een redelijke termijn naar best vermogen zal trachten hieraan te verhelpen.</p>
<p>&nbsp;</p>
<p>14.8 Het contract dooft niet uit noch bij gehele noch bij gedeeltelijke onmogelijkheid van uitvoering ingevolge de wil van de klant, noch bij niet-gebruik, noch ingevolge vreemde oorzaak of overmacht, waarvan het risico steeds ten laste van de klant blijft, die desgevallend niet gerechtigd is om zijn verplichtingen op te schorten.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 15. Domeinnamen</strong></p>
<p>15.1 Indien de klant een domeinnaam bestelt via Systemworks, dan komen de rech- ten verbonden aan deze domeinnaam uitsluitend toe aan de klant. Systemworks staat als agent in voor het beheer van de domeinnaam voor zover de klant hiertoe de verschuldigde jaarlijkse vergoeding betaalt aan Systemworks. Deze beheersovereenkomst is van onbepaalde duur en kan per aangetekende brief worden opgezegd uiterlijk &eacute;&eacute;n maand voor de vervaldag van de domeinnaam registratie.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 16. Gebruik van open source CMS-systemen &ndash; Aansprakelijkheid</strong></p>
<p>16.1 Indien de klant over een CMS-systeem (content management system)</p>
<p>beschikt als deel van de installatie van zijn website, web applicatie of andere online toepassing, dan is de klant als enige verantwoordelijke voor de wijzigingen die hij bewust of onbewust aanbrengt via het CMS-systeem vanaf de dag van de oplevering. De klant draagt in dat ge- val na de oplevering de bewijslast indien hij zou beweren dat de goede werking van de website, web applicatie of andere online toepassing te wijten zou zijn aan Systemworks.</p>
<p>16.2 Gebruik van het CMS-systeem en de administratieve toegang door de klant die zouden resulteren in problemen van welke aard ook met de installatie zijn de uitsluitende verantwoordelijkheid van de klant. Ook voor klanten die gebruik maken van een service overeenkomt voor de updates aan hun CMS-systeem kan geen beroep gedaan worden op de waarborgen of diensten die verleend worden door Systemworks voor herstel van de installatie.</p>
<p>16.3 Er wordt ten allen tijde verondersteld dat de klant zijn CMS-systeem beheert als een goede huisvader en over voldoende technische kennis beschikt hieromtrent.</p>
<p><strong>Artikel 17. Be&euml;indiging overeenkomst &ndash;&nbsp;opschorting van dienstverlening</strong></p>
<p>17.1 Indien de klant zich schuldig maakt aan (i) een zware contractuele wan-prestatie of (ii) aan een contractuele wanprestatie die de klant niet herstelt binnen de 8 dagen na ontvangst van een ter post aangetekende ingebrekestelling, heeft Systemworks het recht om ofwel (1) de overeenkomst te schorsen totdat de klant zijn verbintenissen is nagekomen, ofwel (2) de overeenkomst met onmiddellijke ingang te be&euml;indigen. De niet-betaling van een of meerdere facturen op hun vervaldag, zal altijd worden beschouwd als een zware contractuele wanprestatie.</p>
<p>&nbsp;</p>
<p>17.2 Indien de klant niet, niet behoorlijk of niet tijdig voldoet aan enige betalingsvoorwaarden of aan enige andere verbintenis van het contract, alsmede in onder artikel 18.3 vermelde gevallen, kan Systemworks, zonder enige voorafgaandelijke ingebrekestelling en zonder opzeggingstermijn, de uitvoering van haar verbintenissen opschorten tot aan betaling van de openstaande factuur in hoofdsom of tot aan uitvoering van de verbin- tenis en/of het contract voor ontbonden verklaren wegens contractbreuk lastens en voor risico van de klant, onverminderd recht op schadevergoeding voor Systemworks.</p>
<p>&nbsp;</p>
<p>17.3 Ten niet-limitatieve titel geven volgende gevallen aanleiding tot voormel- de schorsing en/of be&euml;indiging:</p>
<p>&ndash; simultaan gebruik van logins en paswoorden<br />&ndash; gebrek aan volledige overmaking van inlichtingen, invulling en/of on-dertekening van alle documenten eigen aan de dienstverlening en/of</p>
<p>levering van goederen<br />&ndash; upgrade, onderhoud, technische en/of procedurele wijzigingen aan de infrastructuur van Systemworks of door derden<br />&ndash; enig onrechtmatig gebruik of toegang tot de dienst<br />&ndash; het niet respecteren van de Acceptable Use Policy<br />&ndash; gebrek aan beveiliging<br />&ndash; aantasting van de goede naam van Systemworks<br />&ndash; schending van rechten van derden<br />&ndash; niet-verlenen of niet-naleven van garantie en/of domiciliatie na verzoek door Systemworks.</p>
<p>&nbsp;</p>
<p>17.4 Gedurende de schorsing blijft de duurtijd van het contract doorlopen en blijft Systemworks gerechtigd om verder te factureren, waarbij de klant gehou-den blijft deze te betalen.</p>
<p>&nbsp;</p>
<p>17.5 De schorsing wordt binnen de best mogelijk tijd opgeheven na integrale</p>
<p>betaling van alle openstaande facturen in hoofdsom (valuta rekening Systemworks geldt) en na notificatie door de klant aan Systemworks van de integrale en volledige uitvoering van alle verbintenissen in zijne hoofde. Ten titel van heractivatie wordt een forfaitaire kost van &euro; 125 excl. BTW aan de klant gefactureerd.</p>
<p>&nbsp;</p>
<p>17.6 Zelfs in geval van schorsing, blijft Systemworks gerechtigd om op elk later ogenblik het contract voor ontbonden te verklaren wegens contractbreuk lastens en voor risico van de klant, onverminderd recht op schadevergoe- ding voor Systemworks.</p>
<p>&nbsp;</p>
<p>17.7 Systemworks blijf te allen tijde gerechtigd om het contract, geheel of gedeeltelijk, te be&euml;indigen en/of te schorsen indien het vanuit technisch oogpunt, geheel of gedeeltelijk, tijdelijk of permanent, onmogelijk is om de dienst te verstrekken, waarin begrepen ingevolge overmacht, en/of indien de voortzetting ervan, ingevolge technische wijziging, een meerkost voor Systemworks zou inhouden in vergelijking met de op het ogenblik van het aangaan van het contract bestaande kost, ongeacht of zulks op dat ogenblik al dan niet voorzienbaar was en/of Systemworks de dienst al dan niet verder aanbiedt.</p>
<p>&nbsp;</p>
<p>17.8 Indien verschillende contracten met de klant werden aangegaan, is Systemworks, naar keuze, gerechtigd om, in voormelde gevallen, alle contracten dan wel &eacute;&eacute;n of meerdere contracten te be&euml;indigen.</p>
<p>&nbsp;</p>
<p>17.9 Een betwisting, aanspraak, vordering tot schorsing of be&euml;indiging, niet levering of welke andere dan ook van de klant betreffende een onderdeel van het contract of het gehele contract, kan door de klant niet worden ingeroepen opzichtens een ander onderdeel of een ander contract, inzonderheid niet ter rechtvaardiging van de niet-betaling voor deze laatsten.</p>
<p>&nbsp;</p>
<p>17.10 Door de schorsing of be&euml;indiging wordt de toegang tot de dienst, waarin begrepen tot de desgevallende website, web applicatie of andere online toepassing, zowel voor de klant als voor derden, ontoegankelijk.</p>
<p>&nbsp;</p>
<p>17.11 In voormelde gevallen van schorsing en/of be&euml;indiging kan de klant geen aanspraak maken op eender welke schadevergoeding, directe of indirecte, rechtstreekse en onrechtstreekse, of van welke dan ook noch op een vermindering van de prijs voor de duur van een eventuele schorsing.</p>
<p>&nbsp;</p>
<p>17.12 Bij be&euml;indiging van de overeenkomst zal de klant alle door Systemworks verleende diensten betalen, alsook de kosten die Systemworks moet maken als gevolg van deze be&euml;indiging, vermeerderd met een forfaitaire schadevergoeding van 50% van het bedrag dat Systemworks nog had kunnen factureren aan de klant in- dien de overeenkomst volledig zou zijn uitgevoerd. Het eventueel betaald voorschot blijft hoe dan ook verworven voor Systemworks. Bovendien behoudt Agi- le het recht om een hogere schadevergoeding te vorderen indien zij bewijst dat haar werkelijk geleden schade groter is dan de forfaitaire schade zoals hierboven bepaald.</p>
<p>&nbsp;</p>
<p>17.13 In geval van faillissement, stopzetting, gerechtelijk of vrijwillige vereffening of in een gelijkaardige situatie, is het contract automatisch, van rechtswege en zonder enige voorafgaandelijke ingebrekestelling ontbonden lastens en voor risico van de klant, onverminderd recht op schadevergoeding voor Systemworks.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 18. Vertrouwelijkheid &ndash; geheimhoudingsplicht</strong></p>
<p>18.1 Elke partij erkent dat zij geen enkele van de andere verkregen vertrouwelijke informatie zal vrijgeven. Partijen verbinden zich er bovendien toe de commerci&euml;le en technische informatie en de bedrijfsgeheimen die zij vernemen van de andere partij, zelfs na de be&euml;indiging van de overeen- komst, geheim te houden en enkel te gebruiken voor de uitvoering van de overeenkomst.</p>
<p>&nbsp;</p>
<p>18.2 De bepalingen van dit artikel zijn niet van toepassing op: informatie, dat openbaar goed is geworden op een andere manier dan door een inbreuk op enige vertrouwelijkheidsverplichting; informatie, verkregen van een derde partij die deze op een rechtsgeldige wijze heeft verkregen zonder vertrouwelijkheidsverplichting of ontwikkeld; informatie die op verzoek van een publieke of gerechtelijke overheid onthuld moet worden.</p>
<p>&nbsp;</p>
<p>18.3 Elke partij is tevens verantwoordelijk voor de naleving van dit artikel door haar aangestelde, agenten en/of vertegenwoordigers.</p>
<p>&nbsp;</p>
<p>18.4 Dit artikel is van kracht op vertrouwelijke informatie zolang deze infor- matie niet onder de toepassing van artikel 19.2 valt.</p>
<p>&nbsp;</p>
<p>18.5 De klant dient te allen tijde Systemworks integraal te vrijwaren voor elke vorde- ring uitgaande van een derde alsmede Systemworks schadeloos te stellen voor elke directe of indirecte schade veroorzaakt door de niet-naleving van de bepalingen van dit artikel.</p>
<p><strong>Artikel19. Overdracht</strong></p>
<p>19.1 Behoudens voorafgaandelijke schriftelijke toestemming van Systemworks, is het contract en/of daarin vermelde rechten en plichten, noch geheel noch gedeeltelijk, overdraagbaar in hoofde van de klant noch op enige wijze bezwaarbaar noch deel uitmakend van zijn handelsfonds. Bij voorgaandelijke schriftelijke toestemming van Systemworks met een overdracht van een contract in hoofde van de klant, verbindt de klant-overnemer zich tot solidiaire betaling van alle op datum van inwerkingtreding van de overdracht aan Systemworks door de overdrager op grond van het contract verschuldigde sommen in hoofdsom.</p>
<p>&nbsp;</p>
<p>19.2 Systemworks is er steeds toe gerechtigd het contract, en/of daarin vermelde rechten en/of plichten en/of voortvloeiende vorderingen, vergoedin- gen, met in begrip van alle aanhorigheden, geheel of gedeeltelijk, alsmede de eigendom van betreffende goederen, aan een derde over te dragen of in pand te geven. De klant erkent en aanvaardt dat deze derde desgevallend de overgedragen rechten en/of plichten en/of vorderin- gen van Systemworks ten opzichte van de klant kan uitoefenen en verbindt zich ertoe om op het eerste verzoek van Systemworks ieder document te ondertekenen dat vereist is voor de juridische en administratieve regularisatie van de overdracht, die hem desgevallend eenvoudigweg bij aangetekend schrijven kan betekend worden.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 20. Portfolio en Klanten referenties</strong></p>
<p>20.1 De klant gaat ermee akkoord dat de door Systemworks voor de klant ontwik- kelde website, web applicatie of andere online toepassing of de door Systemworks voor de klant geleverde dienstverlening wordt opgenomen in het portfolio van Systemworks (zowel online op de website als op print in welke mogelijke promotionele uitgave van Systemworks dan ook).</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 21. Plaats van uitvoering</strong></p>
<p>21.1 Alle contracten waarbij Systemworks partij is, worden te hare zetel uitgevoerd, tenzij expliciet vermeld in de verkoopsovereenkomst.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 22. Overmacht</strong></p>
<p>22.1 Overmacht situaties zoals bijvoorbeeld stakingen, publieke onrust, administratieve maatregelen en andere onverwachte gebeurtenissen waarover Systemworks geen controle heeft, bevrijden Systemworks, voor de duur van de hinder en voor hun draagwijdte, van haar verbintenissen, zonder recht op enige prijsvermindering of schadevergoeding voor de klant.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 23. Nietigheid</strong></p>
<p>23.1 Indien enige bepaling van deze algemene voorwaarden nietig is, zullen de overige bepalingen volledig van kracht blijven en zullen Systemworks en de klant de nietige bepaling vervangen door een andere bepaling die het doel en de strekking van de nietige bepaling zoveel mogelijk benadert.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 24. Bewijsvoering</strong></p>
<p>24.1 Alle mededelingen, kennisgevingen, contracten, documenten of ander worden door Systemworks rechtsgeldig gegeven aan het in het contract vermelde adres van de klant, en dit zelfs per e-mail, fax of eender welke andere vorm van elektronische registratie.</p>
<p>&nbsp;</p>
<p>24.2 Alle zendingen, behoudens deze per aangetekende brief, die de klant onder artikel 24.1 vermelde wijze verstuurt, kunnen alleen aan Systemworks worden tegengesteld mits een bericht van ontvangst van deze laatste.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Artikel 25. Toepasselijk recht van bevoegde rechtbank</strong></p>
<p>25.1 Het Belgisch recht is van toepassing op de overeenkomsten van Systemworks. Elk geschil met betrekking tot het sluiten, de geldigheid, de uitvoering en/of de be&euml;indiging van deze overeenkomsten zal worden beslecht door de bevoegde rechtbank te Gent.</p>
<p>&nbsp;</p>






    `
}
