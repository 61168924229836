export const contactpage = {
    title: 'Contacteer ons',

    adres: {
        icon : 'bx bx-map',
        title : 'Locatie',
        locatie: 'Belgiëlaan 61 ° 9070 Destelbergen'
    },

    contact : {
        icon: 'bx bx-phone-call',
        title :'Contact',
        numbers: [
            '+32 485 68 60 85',
            '+32 473 58 53 26'
        ],
        email : 'info@systemworks.be'
    },

    openingsuren : {
        icon: 'bx bx-time-five',
        title: 'Openingsuren',
        text1: 'Maandag - Vrijdag: 09:00 - 18:00',
        text2: 'Zaterdag - Zondag: gesloten'

    },

    img: 'assets/img/contact.jpg',



    formulierSectie : {
        subTitle: "Neem contact met ons op",
        title: 'Laat je gegevens hier achter',
        paragraphText: ''
    }

}
