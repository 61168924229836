import {CUSTOM_ELEMENTS_SCHEMA, Injector, Input, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {createCustomElement} from "@angular/elements";
import {ContactButtonComponent} from "./contact-button/contact-button.component";
import {RouterLink} from "@angular/router";
import {ReadmoreButtonComponent} from "./readmore-button/readmore-button.component";



@NgModule({
    declarations: [ContactButtonComponent, ReadmoreButtonComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        ContactButtonComponent, ReadmoreButtonComponent
    ],
    imports: [
        CommonModule,
        RouterLink
    ]
})
export class WebComponentsModule {


    constructor(private injector: Injector) {
        customElements.define('contact-button', createCustomElement(ContactButtonComponent, { injector }));
        customElements.define('readmore-button', createCustomElement(ReadmoreButtonComponent, { injector }));
    }}
