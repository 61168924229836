



export const blogPage = {


    blogData: {
        //count : 3,
        // sectionTitle: {
        //     subTitle: "Blogs",
        //     title: 'Blogs in de kijker',
        //     paragraphText: 'Recente artikels.'
        // },

        visibleItems: [
            7,
            6,
            5,
            4,
            3,
            2,
            1



        ]
    }
}

