import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-hometwo-banner',
    templateUrl: './hometwo-banner.component.html',
    styleUrls: ['./hometwo-banner.component.scss']
})
export class HometwoBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    bannerContent: Content[] = [
        {
            title: 'Laten we samenwerken',
            paragraphText: 'Complete verantwoordelijkheid en zorg voor de ontwikkeling van toepassingen en de configuratie, het onderhoud, de monitoring en tuning van het platform.',
            defaultBtnIcon: 'flaticon-structure',
            defaultBtnText: 'Wie zijn we',
            defaultBtnLink: 'about-us',
             videoBtnIcon: 'flaticon-google-play',
             videoBtnText: 'Watch Video',
             videoBtnLink: 'https://www.youtube.com/watch?v=Y5KCDWi7h9o'
        }
    ]

}
class Content {
    title : string;
    paragraphText : string;
    defaultBtnIcon : string;
    defaultBtnText: string;
    defaultBtnLink : string;
    videoBtnIcon : string;
    videoBtnText: string;
    videoBtnLink : string;
}
