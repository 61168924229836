import { Component, OnInit } from '@angular/core';
import {homePage} from "../../../../data/pages/homepage";
import {BlogService} from "../../../../service/blog.service";
import {Blog} from "../../../../data/blog/blog";

@Component({
    selector: 'app-home-blog',
    templateUrl: './home-blog.component.html',
    styleUrls: ['./home-blog.component.scss']
})
export class HomeBlogComponent implements OnInit {

    page = homePage

    visibleBlogs : Array<Blog> = []

    constructor(private _blogService : BlogService) {
        this.visibleBlogs = this.page.blog.visibleItems.map(id => {
            let blog = _blogService.getBlog(id + '');
            return blog;
        }).filter(b => b != null)
    }



    ngOnInit(): void {
    }


}
