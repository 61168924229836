<ng-container *ngIf="page |async as p">
<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" >
            <h2>{{p.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{p.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
<!--    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>-->
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="simple-page">
    <div class="container">
        <div class="row">
            <div class="mt-4 col-lg-12 col-md-6" [innerHTML]="sanitizedPageContent | async">

            </div>
        </div>
    </div>

</section>

<app-testimonials *ngIf="p.testimonials" [testimonials]="p.testimonials"> </app-testimonials>
</ng-container>
