import {Component, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-contact-button',
    templateUrl: './contact-button.component.html',
    styleUrl: './contact-button.component.scss'
})
export class ContactButtonComponent {

}
