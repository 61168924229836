import {Injectable} from '@angular/core';
import {Blog} from "../data/blog/blog";
import {testBlog} from "../data/blog/testblog";
import {testBlog2} from "../data/blog/testblog2";
import {testBlog3} from "../data/blog/testblog3";
import {testBlog4} from "../data/blog/testblog4";
import {testBlog5} from "../data/blog/testblog5";
import {testBlog6} from "../data/blog/testblog6";
import {testBlog7} from "../data/blog/testblog7";

@Injectable({
    providedIn: 'root'
})
export class BlogService {

    private allBlogs : Array<Blog> = [testBlog, testBlog2,testBlog3,testBlog4,testBlog5,testBlog6,testBlog7]

    constructor() {

    }

    getBlog(id: string): Blog {
        return this.allBlogs.find(b => (b.id+'') == id+'')
    }
}
