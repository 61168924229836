import { Component, OnInit } from '@angular/core';
import {homePage} from "../../../../data/pages/homepage";

@Component({
    selector: 'app-hometwo-boxes',
    templateUrl: './hometwo-boxes.component.html',
    styleUrls: ['./hometwo-boxes.component.scss']
})
export class HometwoBoxesComponent implements OnInit {

    page = homePage

    constructor() {
    }

    ngOnInit(): void {
    }

    protected readonly homePage = homePage;
}
