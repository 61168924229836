import { Component, OnInit } from '@angular/core';
import {privacyEnCookiebeleid} from "../../../data/pages/privacy-en-cookiebeleid";
import {SimplePage} from "../../../data/pages/page";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {TestimonialsComponent} from "../homepage-two/hometwo-testimonials/testimonials.component";
import {AsyncPipe, JsonPipe, NgIf} from "@angular/common";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {map, Observable} from "rxjs";

@Component({
    standalone: true,
    selector: 'app-privacy-page',
    templateUrl: './simple-page.component.html',
    styleUrls: ['./simple-page.component.scss'],

    imports: [
        TestimonialsComponent,
        JsonPipe,
        NgIf,
        AsyncPipe,
        RouterLink
    ]
})
export class SimplePageComponent implements OnInit {

    page : Observable<SimplePage>

    sanitizedPageContent : Observable<SafeHtml>

    constructor(private _route : ActivatedRoute, private _sanitizer : DomSanitizer) {
        this.page = _route.data.pipe(map(data => {
            return data['page']
        }))
        this.sanitizedPageContent = this.page.pipe(map(b => {
            return this._sanitizer.bypassSecurityTrustHtml(b.content);
        }))

    }

    ngOnInit(): void {
    }


}
class pageTitle {
    title : string;
}

