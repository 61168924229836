<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" >
            <h2>{{page.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{page.title}}</li>
            </ul>
        </div>
    </div>
    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
<!--    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>-->
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box" >
                    <div class="back-icon">
                        <i class='{{page.adres.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{page.adres.icon}}'></i>
                    </div>
                    <h3>{{page.adres.title}}</h3>
                    <p>{{page.adres.locatie}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box" >
                    <div class="back-icon">
                        <i class='{{page.contact.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{page.contact.icon}}'></i>
                    </div>
                    <h3>{{page.contact.title}}</h3>
                    <ng-container *ngFor="let nr of page.contact.numbers">
                        <p>Mobile: <a href="tel:{{nr}}">{{nr}}</a></p>
                    </ng-container>
                    <p>E-mail: <a href="mailto:{{page.contact.email}}">{{page.contact.email}}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="contact-info-box" >
                    <div class="back-icon">
                        <i class='{{page.openingsuren.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{page.openingsuren.icon}}'></i>
                    </div>
                    <h3>{{page.openingsuren.title}}</h3>
                    <p>{{page.openingsuren.text1}}</p>
                    <p>{{page.openingsuren.text2}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title"><img src="/assets/img/icon/Blaadje_klein.png" alt="image"> {{page.formulierSectie.subTitle}}</span>
            <h2>{{page.formulierSectie.title}}</h2>
            <p *ngIf="page.formulierSectie.paragraphText">{{page.formulierSectie.paragraphText}}</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-image" >
                    <img [src]="page.img" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <div id="contactForm" [formGroup]="form">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3" >
                                    <input formControlName="name" type="text" [ngClass]="{invalid: form.controls['name'].invalid}" class="form-control" id="name" placeholder="Uw naam">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" formControlName="email" class="form-control" [ngClass]="{invalid: form.controls['email'].invalid}" id="email" placeholder="Uw e-mailadres">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="phone" class="form-control" [ngClass]="{invalid: form.controls['phone'].invalid}" id="phone_number" placeholder="Uw telefoonnummer">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea formControlName="message" id="message" class="form-control" cols="30" rows="6" [ngClass]="{invalid: form.controls['message'].invalid}"  placeholder="Uw bericht"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button (click)="send()" class="default-btn" [disabled]="form.invalid"><i class="flaticon-tick"></i>Verzenden<span></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="map">
<!--    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>-->
    <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB0LWCiX4r_zRo9s_1UEsKNNzM3w8eBh-w&q=Systemworks+bv"></iframe>
</div>
