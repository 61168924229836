import {Component, OnInit} from '@angular/core';
import {contactpage} from "../../../data/pages/contactpage";
import {ToastrService} from "ngx-toastr";
import {HttpClient, HttpClientModule, HttpHeaders} from "@angular/common/http";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {JsonPipe, NgClass, NgForOf, NgIf} from "@angular/common";

@Component({
    standalone: true,
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    imports: [HttpClientModule, ReactiveFormsModule, NgForOf, NgIf, JsonPipe, NgClass],
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {


    page = contactpage

    form: FormGroup;


    constructor(private toastr: ToastrService, private _http:  HttpClient, formBuilder : FormBuilder) {

        this.form =  new FormGroup({
            name: new FormControl<string | null>("", {
                validators: [Validators.required]
            }),
            email: new FormControl<string | null>("", {
                validators: [ Validators.email]
            }),
            phone: new FormControl<string | null>("", {
                validators: []
            }),
            message: new FormControl<string | null>("", {
                validators: [Validators.required]
            })
        });


    }

    send() {

        let value = this.form.value;

        let uploadData = new URLSearchParams();





        // const uploadData = new FormData();
        uploadData.append('name', value['name']);
        uploadData.append('email',value['email']);
        uploadData.append('phone',value['phone']);
        uploadData.append('subject',value['subject']);
        uploadData.append('message',value['message']);
        let headers = new HttpHeaders();
        // headers = headers.append('Content-Type', 'multipart/form-data');
        // headers = headers.append('enctype', 'multipart/form-data');
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded')
        // firstValueFrom(this._http.post('https://app.geo-desk.be/api/contact/geodesk',uploadData,{headers})).then(s => {
        //     this.toastr.success('We nemen ze spoedig mogelijk contact met je op!', 'De postbode is onderweg');
        // },err => {
        //     this.toastr.error(err, 'Hier liep iets fout :-(');
        // })

        //deze webhook (make.com
        firstValueFrom(this._http.post('https://hook.eu2.make.com/wpeh3w16qtsqnzsd5lnhwc7dk28vws0i',uploadData,{headers,responseType: 'text'})).then(s => {
            this.toastr.success('We nemen ze spoedig mogelijk contact met je op!', 'De postbode is onderweg');
        },err => {
            this.toastr.error(err, 'Hier liep iets fout :-(');
            console.error(err)
        })


    }

    ngOnInit(): void {
    }

}
