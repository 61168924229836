import {Blog, PIETER} from "./blog";

// postImg: 'assets/img/blog/img1.jpg',
//     postTitle: 'Six Ways to Make Smarter Decisions',
//     postLink: 'blog-details',
//     postDate: 'April 30, 2024',
//     postAuthorImage: 'assets/img/user1.jpg',
//     postAuthorName: 'Alex Morgan'


export const testBlog3: Blog = {
    id: "3",
    title: 'GEO-Desk gaat van start',
    subTitle: 'Probleemstelling',
    author: PIETER,
    date: "1 oktober 2017",
    image: 'assets/img/blog/Blog3_okt  2017/Blog3_GEODesk1_700x600.jpg',
    content: `

                        <p>Verschillende organisaties voeren geregeld staalnames uit. Dit kan gaan van waterstalen in douches tot het nemen van grondstalen op landbouwpercelen.</p>

                        <p>Het beheer van deze staalnames, de staalnames zelf en de verwerking en controle hiervan is vaak tijdrovend, omslachtig en gebeurt vaak nog op papier, met de bijhorende fouten en overhead tot gevolg. Wanneer staalnames in onderaaneming gebeuren, verloopt de communicatie tussen alle partijen soms stroef en zorgt dit vaak voor een onnodige vertraging en misverstanden. Soms worden er vereisten gesteld door derde partijen (bv. het voormelden van de datums wanneer de staalnames zullen plaatsvinden). Ook hier werken we aan hulpmiddelen om de overhead die dit teweegbrengt tot een minimum te beperken. Het GEO-desk project is na een korte prove-of-concept periode nu in volle ontwikkeling.</p>


                        <blockquote class="wp-block-quote">
                            <p>Every minute you spend in planning saves 10 minutes in execution.</p>
                             <p>This gives you a 1,000 percent return on energy!</p>

                            <cite>Brian Tracy</cite>
                        </blockquote>

                        <!--
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>




                        <div style="display: grid; border solid red 3px; grid-template-columns: repeat(3, auto)" >

                            <div style="background-color: blue">1</div>
                            <div style="background-color: yellow">2</div>
                            <div style="background-color: orange">3</div>
                        </div>
                        -->


                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog3_okt  2017/Blog3_GEODesk2_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog3_okt  2017/Blog3_GEODesk4_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog3_okt  2017/Blog3_GEODesk3_750x650.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>

                        <!--
                        <h3>Four major elements that we offer:</h3>

                        <ul class="features-list">
                            <li><i class='bx bx-badge-check'></i> Scientific skills for getting a better result</li>
                            <li><i class='bx bx-badge-check'></i> Communication skills to getting in touch</li>
                            <li><i class='bx bx-badge-check'></i> A career overview opportunity available</li>
                            <li><i class='bx bx-badge-check'></i> A good work environment for work</li>
                        </ul>
                        -->

                        <h3>Oplossing</h3>
                        <h5>Beheer van staalnames</h5>
                        <p>Staalnames kunnen manueel worden aangemaakt in het systeem, maar doorgaans zal dit via automatische weg verlopen (API verbinding met extern systeem, of import via Excel). Afhankelijk van het 'soort' staalname, zijn er verschillende 'flows' mogelijk die de staalname zal/moet volgen. Zo zal planning, toewijzing, voormelden, uitvoering, controle etc. al dan niet een plaats in deze flow hebben.</p>

                        <h5>De staalname zelf</h5>
                        <p>Voor de staalnemer werd er een mobiele app ontwikkeld die door de staalnemer ter plaatse gebruikt kan worden. Zo kunnen metingen, opmerkingen of andere (te configureren) vragen ter plaatse worden ingegeven. De mobiele app werd ontwikkeld met nadruk op gebruiksgemak. Tijdens een staalname heeft de staalnemer vaak reeds de handen vol, dus moet de bediening vlot en snel gaan. (Ja, zelfs sneller dan de klassieke manier met pen en papier).</p>

                        <h5>Validatie van staalnames</h5>
                        <p>Voor sommige soorten staalnames worden er ook eisen opgelegd hoe staalnames genomen worden (via GPS loggegevens). Eén voorbeeld hiervan is het looppatroon dat een bepaalde vorm moet aannemen). We zijn ervan overtuigd (en de prove-of-concept heeft dat ook bewezen) dat ook dit soort validaties volledig automatisch kan gebeuren. Het spreekt vanzelf dat al deze validaties volledig te configureren zijn.</p>

                        <h5>Naverwerking</h5>
                        <p>Eenmaal de staalname werd uitgevoerd, de eventuele automatische validatie heeft gelopen, dan kan de naverwerking van start gaan.
                           Naverwerking maakt ook deel uit van de 'flow' en hangt dus af van het 'soort' staalname.
                           In het ene geval is dit het rapporteren van de resultaten (in dit geval zijn de resultaten onmiddellijk aanwezig, bv door metingen van de staalnemer ter plaatse). In het andere geval kunnen gegevens worden doorgestuurd / gesynchroniseerd met andere systemen / labo's; daar waar de eigenlijke analyse van de monsters plaatsvindt.</p>
                        <p>&nbsp;</p>

                        <!--
                        <h3>The rise of marketing and why you need it</h3>
                        -->

                        <p>Wil je meer weten over GEO-Desk?</p>
                        <div class="image-left">
                               <img src="/assets/img/icon/logo_GEODesk_72 px.png" alt="plaatje" width="65px">
                         </div>

                         {
                                 btnIcon: 'flaticon-right',
                                 btnText: 'Read More',
                                 link: 'product/clubfox'
                         }
                         <p>&nbsp;</p>


                        <p>Heb je nog vragen of nood aan persoonlijk advies? Contacteer ons.</p>

                        <div class="option-item">
                           <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Contacteer ons<span></span></a>
                        </div>

    `

}
