<section class="funfacts-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4  col-12 " *ngFor="let Funfacts of singleFunfactsBox;">
                <div class="single-funfacts-box">
                    <div class="icon">
                        <img [src]="Funfacts.icon" alt="image">
                    </div>
                    <h3>{{Funfacts.title}}</h3>
                    <p>{{Funfacts.subTitle}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
