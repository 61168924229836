<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
                <li>{{pageTitle.subTitle}}</li>
            </ul>
        </div>
    </div>
    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
<!--    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>-->
<!--    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>-->
</section>

<section class="courses-details-area pb-100">
    <div class="courses-details-image">
        <img src="assets/img/courses/Afbeelding1_geodesk.jpg" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-desc">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false">Curriculum</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="instructor-tab" data-bs-toggle="tab" data-bs-target="#instructor" type="button" role="tab" aria-controls="instructor" aria-selected="false">Instructor</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">Reviews</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="overview" role="tabpanel">
                            <div class="courses-overview">
                                <h3>Bij de voorbereiding</h3>
                                <p>Als labo kun je staalnames aanmaken en inplanne, meteen met bijhorend kaartmateriaal. En dat volledig digitaal.</p>
                                <p>De staalnames worden vervolgens toegewezen aan de beschikbare staalnemer</p>
                                <h3>Tijdens de staalname</h3>
                                <p>Dankzij de app heeft de staalnemer meteen inzicht in alle toegewezen staalnames en de informatie die hij of zij ter plaatse nodig heeft.</p>
                                <p>Ook kan een staalnemer ter plaatse alle meetgegevens digitaal registreren.</p>
                                <h3>De naverwerking</h3>
                                <p>Het is niet meer nodig om alle staalngegevens over te typen. Door het gebruik van GEO-Desk stromen voortaan alle meetgegevens rechtstreeks door.</p>
                                <h3>Beschik jij over een uniek staalname- of labo-proces?</h3>
                                <p>Geen zorgen! De GEO-Desk-oplossing is ontwikkeld om met unieke processen om te kunnen gaan. Met meer dan 20 jaar labo ervaring weten we dat ieder proces anders is. </p>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="curriculum" role="tabpanel">
                            <div class="courses-curriculum">
                                <h3>Python Introduction</h3>
                                <ul>
                                    <li><a routerLink="/courses-details" class="d-flex justify-content-between align-items-center">-->
                                        <span class="courses-name">Python Introduction</span>
                                        <div class="courses-meta">
                                            <span class="questions">5 questions</span>
                                            <span class="status">Preview</span>
                                        </div>
                                    </a></li>
                                </ul>
                                <h3>Stepping into the World of Python</h3>
                                <ul>
                                    <li><a routerLink="/courses-details" class="d-flex justify-content-between align-items-center">-->
                                        <span class="courses-name">NumPy Introduction</span>-
                                        <div class="courses-meta">
                                            <span class="duration">15 Min</span>
                                            <span class="status locked"><i class='flaticon-lock'></i></span>
                                        </div>
                                   </a></li>
                                    <li><a routerLink="/courses-details" class="d-flex justify-content-between align-items-center">-->
                                        <span class="courses-name">NumPy Getting Started</span>
                                        <div class="courses-meta">
                                            <span class="duration">30 Min</span>
                                            <span class="status locked"><i class='flaticon-lock'></i></span>
                                        </div>
                                    </a></li>
                                    <li><a routerLink="/courses-details" class="d-flex justify-content-between align-items-center">
                                        <span class="courses-name">NumPy Creating Arrays</span>
                                        <div class="courses-meta">
                                            <span class="duration">45 Min</span>
                                            <span class="status locked"><i class='flaticon-lock'></i></span>
                                        </div>
                                    </a></li>
                                    <li><a routerLink="/courses-details" class="d-flex justify-content-between align-items-center">
                                        <span class="courses-name">NumPy Array Indexing</span>
                                        <div class="courses-meta">
                                            <span class="questions">4 questions</span>
                                            <span class="duration">1 Hour</span>
                                            <span class="status locked"><i class='flaticon-lock'></i></span>
                                        </div>
                                    </a></li>
                                    <li><a routerLink="/courses-details" class="d-flex justify-content-between align-items-center">
                                        <span class="courses-name">NumPy Array Slicing</span>
                                        <div class="courses-meta">
                                            <span class="duration">1.5 Hour</span>
                                            <span class="status locked"><i class='flaticon-lock'></i></span>
                                        </div>
                                    </a></li>
                                </ul>
                                <h3>Python MySQL</h3>
                                <ul>
                                    <li><a routerLink="/courses-details" class="d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Python MySQL</span>
                                        <div class="courses-meta">
                                            <span class="duration">01 Hour</span>
                                            <span class="status locked"><i class='flaticon-lock'></i></span>
                                        </div>
                                    </a></li>
                                    <li><a routerLink="/courses-details" class="d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Python MySQL Create Database</span>
                                        <div class="courses-meta">
                                            <span class="questions">3 questions</span>
                                            <span class="duration">1.1 Hour</span>
                                            <span class="status locked"><i class='flaticon-lock'></i></span>
                                        </div>
                                    </a></li>
                                    <li><a routerLink="/courses-details" class="d-flex justify-content-between align-items-center">
                                        <span class="courses-name">Python MySQL Create Table</span>
                                        <div class="courses-meta">
                                            <span class="duration">1.5 Hour</span>
                                            <span class="status locked"><i class='flaticon-lock'></i></span>
                                        </div>
                                    </a></li>
                                </ul>
                            </div>
                        <div class="tab-pane fade" id="instructor" role="tabpanel">
                            <div class="courses-instructor">
                                <div class="single-advisor-box">
                                    <div class="row align-items-center">
                                        <div class="col-lg-4 col-md-4">
                                            <div class="advisor-image">
                                                <img src="assets/img/speaker/img2.jpg" alt="image">
                                            </div>
                                        </div>

                                        <div class="col-lg-8 col-md-8">
                                            <div class="advisor-content">
                                                <h3>Sarah Taylor</h3>
                                                <span class="sub-title">Agile Project Expert</span>
                                                <ul class="social-link">
                                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="reviews" role="tabpanel">
                            <div class="courses-reviews">
                                <h3>Course Rating</h3>
                                <div class="rating">
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star"></span>
                                </div>
                                <div class="rating-count">
                                    <span>4.1 average based on 4 reviews.</span>
                                </div>
                                <div class="row">
                                    <div class="side">
                                        <div>5 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-5"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>02</div>
                                    </div>
                                    <div class="side">
                                        <div>4 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-4"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>03</div>
                                    </div>
                                    <div class="side">
                                        <div>3 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-3"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>04</div>
                                    </div>
                                    <div class="side">
                                        <div>2 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-2"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>05</div>
                                    </div>
                                    <div class="side">
                                        <div>1 star</div>
                                    </div>
                                    <div class="middle">
                                        <div class="bar-container">
                                            <div class="bar-1"></div>
                                        </div>
                                    </div>
                                    <div class="side right">
                                        <div>00</div>
                                    </div>
                                </div>
                            </div>
                            <div class="courses-review-comments">
                                <h3>3 Reviews</h3>
                                <div class="user-review">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">James Anderson</span>
                                    </div>
                                    <span class="d-block sub-comment">Excellent</span>
                                    <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user2.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">Sarah Taylor</span>
                                    </div>
                                    <span class="d-block sub-comment">Video Quality!</span>
                                    <p>Was really easy to implement and they quickly answer my additional questions!</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">David Warner</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Coding!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user4.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>

                                        <span class="d-inline-block">King Kong</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Video!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-details-info">
                    <div class="image">
                        <img src="assets/img/courses/img1.jpg" alt="image">
                        <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="link-btn popup-youtube"></a>
                        <div class="content">
                            <i class="flaticon-google-play"></i>
                            <span>Course Preview</span>
                        </div>
                    </div>

                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-price-tag"></i> Price</span>
                                $49
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Instructor</span>
                                Sarah Taylor
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-stopwatch"></i> Duration</span>
                                7 weeks
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-education"></i> Lessons</span>
                                25
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Enrolled</span>
                                255 students
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-open-padlock"></i> Access</span>
                                Lifetime
                            </div>
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a routerLink="/courses-details" class="default-btn"><i class="flaticon-shopping-basket"></i>Add to Cart<span></span></a>
                        <a routerLink="/courses-details" class="default-btn"><i class="flaticon-price-tag"></i>Buy Now<span></span></a>
                    </div>

                    <div class="courses-share">
                        <div class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>

                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</section>

<app-related-courses></app-related-courses>
