import {Component, Input, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {JsonPipe, NgForOf} from "@angular/common";

@Component({
    standalone: true,
    selector: 'app-testimonials',
    templateUrl: './testimonials.component.html',
    imports: [
        RouterLink,
        NgForOf,
        JsonPipe
    ],
    styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

    constructor() { }

    @Input()
    titleSection : SectionTitleContent

    @Input()
    testimonials : Array<TestimonialsBoxContent> = []

    ngOnInit(): void {
        if (this.titleSection) {
            this.sectionTitle.push(this.titleSection)
        }
    }

    sectionTitle: SectionTitleContent[] = []


    testimonialsBtn: Btn[] = [
        // {
        //     link: "/",
        //     icon: 'flaticon-view',
        //     text: 'Check Out All Reviews'
        // }
    ]

}
class SectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class TestimonialsBoxContent {
    clientImg : string;
    paragraphText : string;
    clientName : string;
    clientDesignation : string;
}
class Btn {
    link : string;
    icon : string;
    text : string;
}
